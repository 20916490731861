/* GLOBAL COLORS */

/* Gray */
$gray-50: #fafafa;
$gray-100: #ececee;
$gray-200: #dcdce0;
$gray-300: #cacace;
$gray-400: #a7a7af;
$gray-500: #7b7b84;
$gray-600: #505059;
$gray-700: #3a3a41;
$gray-800: #242428;
$gray-900: #14141a;
$gray-950: #0e0e11;

/* Black */
$black-50: rgba(0, 0, 0, 0.05);
$black-100: rgba(0, 0, 0, 0.1);
$black-200: rgba(0, 0, 0, 0.2);
$black-300: rgba(0, 0, 0, 0.3);
$black-400: rgba(0, 0, 0, 0.4);
$black-500: rgba(0, 0, 0, 0.5);
$black-600: rgba(0, 0, 0, 0.6);
$black-700: rgba(0, 0, 0, 0.7);
$black-800: rgba(0, 0, 0, 0.8);
$black-900: rgba(0, 0, 0, 0.9);
$black-950: rgba(0, 0, 0, 0.95);
$black-1: rgba(0, 0, 0, 1);

/* White */
$white-50: rgba(255, 255, 255, 0.05);
$white-100: rgba(255, 255, 255, 0.1);
$white-200: rgba(255, 255, 255, 0.2);
$white-300: rgba(255, 255, 255, 0.3);
$white-400: rgba(255, 255, 255, 0.4);
$white-500: rgba(255, 255, 255, 0.5);
$white-600: rgba(255, 255, 255, 0.6);
$white-700: rgba(255, 255, 255, 0.7);
$white-800: rgba(255, 255, 255, 0.8);
$white-900: rgba(255, 255, 255, 0.9);
$white-950: rgba(255, 255, 255, 0.95);
$white-1: rgba(255, 255, 255, 1);

/* Purple */
$purple-50: #f4f0ff;
$purple-100: #e9e0ff;
$purple-200: #c8b4fe;
$purple-300: #a789fb;
$purple-400: #7f54f8;
$purple-500: #6b38f5;
$purple-600: #5626d9;
$purple-700: #4523a4;
$purple-800: #361d7c;
$purple-900: #27184e;
$purple-950: #1d123a;

/* Coral */
$coral-50: #ffeef1;
$coral-100: #ffdbe2;
$coral-200: #feb4c1;
$coral-300: #fb899e;
$coral-400: #f0607b;
$coral-500: #e93a65;
$coral-600: #c0264c;
$coral-700: #a42343;
$coral-800: #7c1d35;
$coral-900: #4e1822;
$coral-950: #361118;

/* Red */
$red-50: #ffebeb;
$red-100: #ffd8d6;
$red-200: #feb1af;
$red-300: #fb918d;
$red-400: #f2645f;
$red-500: #e03e38;
$red-600: #bf2722;
$red-700: #9e231f;
$red-800: #721b18;
$red-900: #4f1917;
$red-950: #371110;

/* Green */
$green-50: #f1fdf6;
$green-100: #dbfae7;
$green-200: #c7edc5;
$green-300: #9bdd98;
$green-400: #66c563;
$green-500: #45b441;
$green-600: #318b2e;
$green-700: #296e27;
$green-800: #13582d;
$green-900: #113b21;
$green-950: #0b2816;

/* Blue */
$blue-50: #ebf2ff;
$blue-100: #d6e6ff;
$blue-200: #afcdfe;
$blue-300: #8db8fb;
$blue-400: #5f99f7;
$blue-500: #357ced;
$blue-600: #175dcf;
$blue-700: #2052a2;
$blue-800: #193d76;
$blue-900: #152847;
$blue-950: #0e1b2f;

/* Yellow */
$yellow-50: #fffdeb;
$yellow-100: #fff6d6;
$yellow-200: #feedaf;
$yellow-300: #fbe38d;
$yellow-400: #f4d257;
$yellow-500: #f4c725;
$yellow-600: #c59f16;
$yellow-700: #957b1d;
$yellow-800: #6d5b17;
$yellow-900: #3f3612;
$yellow-950: #2b280d;

/* Primary base colors */
$primary-50: $purple-50;
$primary-100: $purple-100;
$primary-200: $purple-200;
$primary-300: $purple-300;
$primary-400: $purple-400;
$primary-500: $purple-500;
$primary-600: $purple-600;
$primary-700: $purple-700;
$primary-800: $purple-800;
$primary-900: $purple-900;
$primary-950: $purple-950;

/* Secondary base colors */
$secondary-50: $coral-50;
$secondary-100: $coral-100;
$secondary-200: $coral-200;
$secondary-300: $coral-300;
$secondary-400: $coral-400;
$secondary-500: $coral-500;
$secondary-600: $coral-600;
$secondary-700: $coral-700;
$secondary-800: $coral-800;
$secondary-900: $coral-900;
$secondary-950: $coral-950;

/* Neutral base colors */
$neutral-white: $white-1;
$neutral-50: $gray-50;
$neutral-100: $gray-100;
$neutral-200: $gray-200;
$neutral-300: $gray-300;
$neutral-400: $gray-400;
$neutral-500: $gray-500;
$neutral-600: $gray-600;
$neutral-700: $gray-700;
$neutral-800: $gray-800;
$neutral-900: $gray-900;
$neutral-950: $gray-950;
$neutral-black: $black-1;

/* Negative */
$negative-50: $red-50;
$negative-100: $red-100;
$negative-500: $red-500;
$negative-800: $red-800;
/* Positive */
$positive-50: $green-50;
$positive-100: $green-100;
$positive-200: $green-200;
$positive-500: $green-500;
$positive-800: $green-800;
/* Info */
$info-50: $blue-50;
$info-500: $blue-500;
$info-800: $blue-800;
/* Warning */
$warning-50: $yellow-50;
$warning-500: $yellow-500;
$warning-800: $yellow-800;

/***************************** LIGHT THEME *****************************/

/*************** FILL ***************/
/* Background */
$fill-bg-screen: $neutral-50;
$fill-bg-inverse: $neutral-black;
$fill-bg-card: $neutral-white;
$fill-bg-disable: $neutral-300;
/* Primary */
$fill-primary-default: $primary-500;
$fill-primary-hover: $primary-400;
$fill-primary-press: $primary-600;
$fill-primary-selected: $primary-500;
$fill-primary-intense: $primary-800;
$fill-primary-subtle: $primary-50;
$fill-primary-subtle-hover: $primary-100;
$fill-primary-subtle-press: $primary-200;
$fill-primary-subtle-selected: $primary-500;

/* Secondary */
$fill-secondary-default: $secondary-500;
$fill-secondary-hover: $secondary-400;
$fill-secondary-press: $secondary-600;
$fill-secondary-selected: $secondary-500;
$fill-secondary-intense: $secondary-800;

/* Neutral */
$fill-neutral-default: $neutral-950;
$fill-neutral-hover: $neutral-800;
$fill-neutral-press: $neutral-700;
$fill-neutral-selected: $neutral-950;
$fill-neutral-disable: $neutral-200;
$fill-neutral-subtle: $black-50;
$fill-neutral-subtle-hover: $black-100;
$fill-neutral-subtle-press: $black-200;
$fill-neutral-subtle-selected: $neutral-950;
$fill-neutral-inverse: $neutral-50;
$fill-neutral-inverse-hover: $neutral-200;
$fill-neutral-inverse-press: $neutral-300;
$fill-neutral-inverse-selected: $neutral-50;
$fill-neutral-inverse-disable: $neutral-800;

/* Positive */
$fill-positive-intense: $positive-200;
$fill-positive-subtle: $positive-50;
$fill-positive-subtle-hover: $positive-100;

/* Negative */
$fill-negative-subtle-hover: $negative-100;
$fill-negative-intense: $negative-800;
$fill-negative-subtle: $negative-50;

/* Info */
$fill-info-subtle: $info-50;
$fill-info-intense: $info-800;

/* Warning */
$fill-warning-subtle: $warning-50;

/*************** CONTENT ***************/
$content-info: $info-500;
$content-default: $neutral-900;
$content-inverse: $neutral-50;
$content-description: $neutral-600;
$content-disable: $neutral-400;
$content-subtle: $neutral-300;
$content-negative: $negative-500;
$content-positive-intense: $positive-800;
$content-primary: $primary-500;

$content-warning-intense: $warning-800;

/*************** STROKE ***************/
$stroke-bg-card: $neutral-200;
$stroke-bg-screen: $neutral-100;

$stroke-primary-default: $primary-500;
$stroke-primary-hover: $primary-400;
$stroke-primary-press: $primary-600;
$stroke-primary-selected: $primary-500;
$stroke-primary-subtle: $primary-200;

$stroke-neutral-subtle: $neutral-200;
$stroke-neutral-selected: $neutral-950;
$stroke-neutral-default: $neutral-950;
$stroke-neutral-medium: $neutral-400;
$stroke-neutral-press: $neutral-950;

$stroke-negative-default: $negative-500;

$stroke-positive-default: $positive-500;

$stroke-info-default: $info-500;

$stroke-warning-default: $warning-500;

/*************** DISPLAY ***************/
$display-neutral-light: $black-100;

/*************** FIXED ***************/
$fixed-inverse: $neutral-50;
