@import '../../global/styles/config';
.toggle {
  @include flex-row;
  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 26px;
    background-color: $fill-neutral-subtle;
    display: block;
    border-radius: 100px;
    position: relative;
  }

  label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 20px;
    height: 20px;
    background: $fixed-inverse;
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + label {
    background: $fill-primary-default;
  }

  input:checked + label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 40px;
  }
}
