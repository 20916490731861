@import '../../../global/styles/config';

.game-banner {
  @include flex-column;
  gap: 20px;
  align-items: center;
  position: relative;
  border-radius: $corner-radius-tertiary;
  padding: $gap-secondary $padding-secondary;
  box-sizing: border-box;
  width: 100%;
  background-color: $neutral-white;
  z-index: 0;

  &__back {
    @include flex-row;
    justify-content: space-between;
    width: 100%;

    > a {
      @include flex-row;
      color: $content-default;
      font-weight: 600;
      align-items: center;
      gap: 5px;

      > svg > path {
        stroke: $content-default;
      }
    }
  }

  &__main {
    @include flex-row;

    align-items: center;
    gap: 16px;
    width: 100%;

    @media (min-width: $screen-md) {
      border-radius: $corner-radius-tertiary;
    }

    &_image {
      height: 65px;
      width: 65px;

      @media (min-width: $screen-md) {
        width: 80px;
        height: 80px;
      }

      > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }

    &_info {
      flex: 1;

      @media (min-width: $screen-md) {
        @include flex-column;
      }

      > h1 {
        @include text-body-semibold;
        @include text-clamp(1);

        @media (min-width: $screen-md) {
          align-self: baseline;
          @include h4-semibold;
        }
      }

      > h2 {
        @include text-body-small-normal;
        @include text-clamp(1);
        color: $content-description;

        @media (min-width: $screen-md) {
          @include text-body-small-normal;
          align-self: baseline;
        }
      }

      &_stats {
        @include flex-row;
        flex-wrap: wrap;
        width: 100%;
        gap: 20px;
        align-items: center;
        margin-top: 10px;

        .chain-logo {
          @include flex-row;
          flex-direction: row-reverse;
          width: unset;
        }

        p {
          @include text-body-small-semibold;
          font-weight: $font-weight-bold;

          @media (min-width: $screen-md) {
            @include text-body-semibold;
            font-weight: $font-weight-bold;
          }
        }

        p span {
          font-weight: normal;
        }
      }
    }
  }
}
