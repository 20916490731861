@import '../../global/styles/config';

.select-container {
  > label {
    @include text-body-semibold;
    display: block;
    color: $content-description;
    margin-bottom: $gap-content;
  }

  .select {
    @include flex-column;
    width: 100%;
    height: 40px;
    position: relative;
    border-bottom: 1px solid $stroke-neutral-subtle;

    &:hover {
      border-radius: $corner-radius-secondary $corner-radius-secondary 0px 0px;
      border-bottom: 1px solid $stroke-neutral-selected;
      background: $fill-neutral-subtle;
    }

    &__selected {
      @include text-body-medium;
      @include flex-row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      text-align: left;
      padding: 0 10px;
      color: $content-default;

      > svg {
        path {
          stroke: $content-default;
        }
      }

      &[disabled] {
        border-radius: $corner-radius-secondary;
        background: $fill-neutral-disable;

        > div {
          > p {
            color: $content-subtle;

            &.placeholder {
              color: $content-disable;
            }
          }
        }

        > svg {
          display: none;
        }
      }

      > div {
        @include flex-row;
        align-items: center;
        gap: 5px;
        > p {
          @include flex-row;
          @include center;
          @include text-body-normal;

          &.placeholder {
            color: $content-subtle;
          }
        }
      }

      > div > p {
        @include text-clamp(1);
        color: $content-default;
      }
    }

    &__options {
      @include custom-scroll;
      position: absolute;
      top: 50px;
      left: 0;
      right: 0;
      border-radius: $corner-radius-primary;
      background: $fill-bg-screen;
      box-shadow: 0px 0px 36px 0px $display-neutral-light, 0px 20px 32px -24px $display-neutral-light;
      overflow: hidden;
      z-index: 2;
      max-height: 200px;
      overflow-y: auto;
      padding: $padding-content;

      > button {
        @include text-body-small-medium;
        width: 100%;
        height: 40px;
        color: $content-default;
        text-align: left;
        padding-left: 10px;

        &:hover {
          border-radius: $corner-radius-secondary;
          background: $fill-neutral-subtle;
        }
      }

      &_selected {
        background-color: $fill-neutral-subtle;
      }
    }
  }

  .error-msg {
    @include error-msg;
  }
}
