@import '../../../global/styles/config';

.leave-organization-modal {
  min-height: unset;
  width: 100%;
  padding: 15px;

  > div:not(:first-child):not(:last-child) {
    @include flex-column;
    margin-bottom: 15px;
  }

  &__heading {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    > h2 {
      @include h4-semibold;
    }
    > button {
      display: flex;
    }
  }

  &__actions {
    @include flex-row;
    @include center;
    gap: 10px;
    padding-top: 15px;
    > button {
      width: 150px;
      height: 40px;

      @media (min-width: $screen-lg) {
        height: 55px;
      }
    }
  }

  @media screen and (min-width: $screen-md) {
    width: 500px;
  }

  @media screen and (min-width: $screen-lg) {
    width: 730px;
  }
}
