@import '../../global/styles/config';

.radio-buttons {
  @include flex-column;
  flex: 1;
  gap: $gap-primary;

  > label {
    @include text-body-semibold;
    color: $content-description;
  }

  .radio-button {
    width: max-content;
    height: 16px;

    input[type='radio'] {
      display: none;

      &:not(:checked) + label:before {
        border: 1px solid $content-default;
        background: none;
        animation: ripple 0.2s linear forwards;
      }

      &:not(:checked) + label:after {
        transform: scale(1);
        background: none;
      }

      &:checked + label {
        font-weight: bold;
      }

      &:checked + label:before {
        background: unset;
        animation: ripple 0.2s linear forwards;
      }

      &:checked + label:after {
        transform: scale(1);
        border: 4px solid $stroke-primary-default;
      }

      &:disabled:checked + label:before {
        border: 2px solid $content-primary;
      }

      &:disabled:checked + label:after {
        background: $stroke-neutral-medium;
      }

      &:disabled:not(:checked) + label:before {
        border: 2px solid $stroke-neutral-medium;
      }

      &:disabled:not(:checked) + label:after {
        background: none;
      }
    }

    label {
      @include text-body-small-normal;
      display: inline-block;
      position: relative;
      padding: 0 30px;
      margin-bottom: 3px;
      cursor: pointer;
      vertical-align: bottom;

      &:before,
      &:after {
        position: absolute;
        content: '';
        border-radius: 50%;
        transition: all 0.3s ease;
        transition-property: transform, border-color;
      }

      &:before {
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
      }

      &:after {
        top: 0px;
        left: 0px;
        width: 12px;
        height: 12px;
        transform: scale(0);
        background: none;
      }
    }
  }

  &_options {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    row-gap: $gap-primary;
  }
}
