@import '../../../global/styles/config';

.game-action-modal {
  @include flex-column;
  position: absolute;
  width: 300px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;
  border-radius: $corner-radius-primary;
  border: 1px solid $stroke-bg-card;
  background: $fill-bg-card;
  align-items: center;

  @media (min-width: $screen-md) {
    width: 400px;
  }

  > .lds-ring {
    margin: 100px;
  }

  > button {
    margin: 10px;
    align-self: flex-end;
  }

  &__success {
    margin: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      path {
        stroke: $content-positive-intense;
      }
    }
  }

  &__authorize {
    > p {
      text-align: center;
    }

    > img {
      display: block;
      margin: 0 auto;
      margin-bottom: 10px;
      height: 120px;
      width: 120px;
      border-radius: $corner-radius-primary;
      object-fit: cover;
    }

    > h3 {
      @include text-clamp(1);
      margin-bottom: 25px;
      text-align: center;
    }
    > div {
      @include flex-row;
      width: 100%;
      justify-content: center;
      gap: 20px;
      margin-top: 25px;

      button {
        height: 40px;
        flex-basis: 47%;
        margin: 0 10px;

        @media (min-width: $screen-md) {
          flex-basis: 150px;
        }
      }
    }
  }
}
