@import '../../../global/styles/config';
.new-brand-modal {
  width: 100%;
  padding: 15px;
  border-radius: $corner-radius-primary;
  overflow: auto;

  &__heading {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    > h2 {
      @include h4-semibold;
    }
    > button {
      display: flex;
    }
  }

  .file-upload-container {
    margin-top: 20px;
  }

  &__actions {
    @include flex-row;
    @include center;
    gap: 10px;
    padding-top: 15px;
    > button {
      width: 150px;
      height: 40px;
    }
  }

  @media screen and (min-width: $screen-md) {
    width: 500px;
    &__actions {
      justify-content: flex-end;
    }
  }
}
