@import '../../../global/styles/config';

.new-game-modal {
  &__main {
    padding: $padding-secondary;

    > div {
      *:not(button) {
        color: $content-default;
      }

      h2 {
        font-size: 20px;
      }

      h3 {
        font-size: 17px;
      }

      p {
        font-size: 14px;
      }
    }
  }
}
