@import '../../../global/styles/config';

.registration-page {
  @include flex-column;
  gap: 40px;
  padding: 15px;
  background: $fill-bg-screen;
  position: relative;
  overflow: hidden;
  min-height: 100vh;

  &::before {
    content: '';
    position: absolute;
    top: unset;
    left: -400px;
    bottom: -400px;
    width: 1046px;
    height: 612px;
    background-clip: border-box;

    border-radius: 1046px;
    background: radial-gradient(
      767.85% 113.99% at 64.21% 50%,
      rgba(87, 0, 251, 0) 0%,
      rgba(255, 60, 89, 0.2) 52.84%,
      rgba(122, 53, 255, 0) 100%
    );
    filter: blur(100px);
    z-index: 0;
  }

  &::after {
    content: '';
    position: absolute;
    top: -300px;
    right: -400px;
    width: 809.65px;
    height: 713px;
    border-radius: 809.65px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(102, 7, 255, 0.2) 0%, rgba(111, 0, 252, 0) 100%);
  }

  &__logo {
    align-self: center;
    padding-top: 30px;
    z-index: 1;

    > img {
      width: 150px;

      @media (min-width: $screen-lg) {
        width: 350px;
      }
    }
  }

  &__form {
    @include flex-column;
    @include center;
    padding: 0 24px;
    border-radius: 16px;
    padding: 16px;
    z-index: 1;
    max-width: 450px;
    width: 100%;
    align-self: center;
    border-radius: $corner-radius-tertiary;
    border: 1px solid $stroke-bg-card;
    background: $fill-bg-card;

    box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.1), 0px 20px 32px -24px rgba(0, 0, 0, 0.1);

    @media (min-width: $screen-lg) {
      max-width: 690px;
    }

    > h2 {
      @include label-medium;
      text-align: center;
      margin: 25px;

      > a {
        @include label-medium;
        color: $content-primary;
      }
    }

    &_referee {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      padding: 4px;
      border-radius: 80px;
      margin: 24px 0 16px 0;

      > img {
        width: 35px;
        height: 35px;
        object-fit: cover;
        border-radius: 100px;
      }
    }

    &_heading {
      margin-bottom: 40px;
      > h1 {
        @include h4-semibold;
        text-align: center;
      }
    }

    &_inputs {
      @include flex-column;
      width: 100%;

      &_name {
        @media (min-width: $screen-lg) {
          @include flex-row;
          gap: 16px;
          > div {
            flex: 1;
          }
        }
      }

      > button {
        align-self: center;
        width: 200px;
        height: 56px;
        margin-top: 40px;
      }

      &_terms {
        @include flex-column;
        margin-top: 10px;
        gap: 20px;
        > div {
          p {
            @include text-body-small-normal;

            a {
              @include text-body-small-bold;
              color: $content-info;
              margin-left: 5px;
              text-decoration: underline;
            }
          }
        }

        .checkbox-container {
          @include text-body-small-normal;
        }
      }
    }
  }
}
