@import '../../../global/styles/config';

.preview-offer-modal {
  @include flex-column;
  width: 650px;
  min-height: 680px;

  &__heading {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $gap-primary;

    > h1 {
      @include h4-semibold;
      color: $content-default;
    }

    > button {
      svg {
        path {
          stroke: $content-default;
        }
      }
    }
  }

  &__tabs {
    margin-bottom: $gap-primary;
  }

  &__content {
    @include flex-column;
    gap: $gap-primary;

    > h2 {
      @include h4-bold;
      color: $content-default;
    }

    &_sender {
      @include flex-row;
      align-items: center;
      gap: $gap-tertiary;

      > img {
        width: 40px;
        height: 40px;
      }

      > span {
        @include text-body-medium;
      }
    }

    > img {
      width: 450px;
      height: 450px;
      display: block;
      margin: 0 auto;
      object-fit: cover;
    }

    > p {
      @include text-body-medium;
      color: $content-default;
    }

    > button {
      width: max-content;
      padding: 0 $padding-primary;
      height: 40px;
      margin: 0 auto;
    }
  }

  > div[class*='--mobile'] {
    background-image: url('./images/mobile.png');
    background-repeat: no-repeat;
    background-size: 280px auto;
    background-position: center;
    padding: 18px 14px;
    height: 570px;
    width: 280px;
    margin: 50px auto;
    overflow: hidden;

    > h2 {
      @include text-body-bold;
      width: 220px;
      margin: 10px auto 0 auto;
    }

    .preview-offer-modal__content_sender {
      margin-left: 14px;

      > img {
        width: 30px;
        height: 30px;
      }

      > span {
        @include text-caption-normal;
      }
    }

    > img {
      width: 225px;
      height: 225px;
    }

    > p {
      @include text-caption-normal;
      margin: 0 14px;
    }
  }
}
