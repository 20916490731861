@import '../../global/styles/config';

.status-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 2px;
  width: 80px;
  box-sizing: border-box;
  @include text-body-small-medium;
  text-transform: capitalize;

  &_SUCCESS {
    @extend .status-tag;
    border: 1px solid $stroke-positive-default;
    background: $fill-positive-subtle;
    color: $content-positive-intense;
  }

  &_PENDING {
    @extend .status-tag;
    border-color: $stroke-warning-default;
    color: $content-warning-intense;
    background: $fill-warning-subtle;
  }

  &_FAILED {
    @extend .status-tag;
    border: 1px solid $stroke-negative-default;
    background: $fill-negative-subtle;
    color: $content-negative;
  }
}
