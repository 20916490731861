@import '../../../../global/styles/config';

.organization-game-settings {
  @include flex-column;
  background-color: $fill-bg-card;
  border-bottom-left-radius: $corner-radius-tertiary;
  border-bottom-right-radius: $corner-radius-tertiary;

  &__main {
    width: 100%;
    padding: 30px 0;

    &__step-container {
      @include flex-column;
      gap: $gap-primary;

      button.submit-step {
        margin-left: auto;
        padding: $padding-primary;
      }
    }
  }

  &__aside {
    .aside-menu__desktop {
      background-color: $fill-bg-card;
      * {
        color: $gray-900;
      }
    }

    .aside-menu__mobile {
      display: none;
    }
  }

  @media screen and (min-width: $screen-md) {
    padding-bottom: 0;
    @include flex-row;

    &__aside {
      @include flex-column;
      > aside {
        flex: 1;
      }
    }

    &__main {
      width: 100%;
      padding: 30px 20px;
    }
  }
}
