@import '../../../global/styles/config';

.organization-view-page {
  @include flex-column;
  flex: 1;
  padding: 0 15px 15px;
  position: relative;

  z-index: 1;
  @media only screen and (min-width: $screen-xl) {
    background-color: unset;
    align-items: center;

    > * {
      width: $layout-width;
    }
  }

  @media (min-width: $screen-xxl) {
    &::before {
      background-position: top -200px right -300px, left -100px bottom;
    }
  }
}
