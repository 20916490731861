@import '../../../global/styles/config';

.login-page {
  @include flex-column;
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding: 25px;
  background: $fill-bg-screen;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: unset;
    left: -400px;
    bottom: -400px;
    width: 1046px;
    height: 612px;
    background-clip: border-box;

    border-radius: 1046px;
    background: radial-gradient(
      767.85% 113.99% at 64.21% 50%,
      rgba(87, 0, 251, 0) 0%,
      rgba(255, 60, 89, 0.2) 52.84%,
      rgba(122, 53, 255, 0) 100%
    );
    filter: blur(100px);
    z-index: 0;
  }

  &::after {
    content: '';
    position: absolute;
    top: -300px;
    right: -400px;
    width: 809.65px;
    height: 713px;
    border-radius: 809.65px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(102, 7, 255, 0.2) 0%, rgba(111, 0, 252, 0) 100%);
  }

  &__logo {
    align-self: center;
    padding: 30px 0 40px;

    @media (min-width: $screen-lg) {
      padding: 55px 0 80px;
    }

    > img {
      width: 150px;

      @media (min-width: $screen-lg) {
        width: 350px;
      }
    }
  }

  &__form {
    @include flex-column;
    align-self: center;
    width: 100%;
    max-width: 450px;
    padding: 20px;
    border-radius: $corner-radius-tertiary;
    border: 1px solid $stroke-bg-card;
    background: $fill-bg-card;

    /* shadow-bottom-300 */
    box-shadow: 0px 0px 36px 0px var(--display-neutral-light, rgba(0, 0, 0, 0.1)),
      0px 20px 32px -24px var(--display-neutral-light, rgba(0, 0, 0, 0.1));

    position: relative;

    @media (min-width: $screen-lg) {
      max-width: 520px;
    }

    > h1 {
      @include h4-semibold;
      text-align: center;
      margin-bottom: 30px;
      padding-bottom: 20px;
    }

    > a {
      &:first-of-type {
        text-align: center;
      }
      @include label-medium;
      display: block;
      width: 100%;
      cursor: pointer;
      text-align: right;

      &:last-of-type {
        @include label-medium;
        margin: 20px 0;
        text-align: center;

        > span {
          @include label-medium;
          color: $content-primary;
        }
      }
    }

    > button {
      @include text-body-semibold;
      width: 200px;
      height: 56px;
      align-self: center;
      margin-top: 50px;
    }

    &_error {
      @include label-medium;
      width: 100%;
      color: $content-negative;
      text-align: center;
    }
    &_email {
      display: flex;
      flex-direction: column;
      align-items: center;

      > p {
        @include label-medium;
        margin-bottom: 25px;
        text-align: center;

        @media (min-width: $screen-lg) {
          text-align: center;
        }

        > button {
          @include label-medium;
          color: $content-primary;
          margin-left: 5px;
        }
      }
    }
  }
}
