@import '../../../global/styles/config';

.organization_dashboard_page {
  &__main {
    @include flex-column;
    gap: 40px;
    width: 100%;

    @media (min-width: $screen-md) {
      flex-direction: row;
    }

    > div {
      h2 {
        margin-bottom: 12px;
      }
    }

    .loading_fallback_container {
      position: relative;
      height: 100px;
      background-color: transparent;
    }

    .org-banner__stats {
      .statistics-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
        gap: 24px;

        @media (min-width: $screen-lg) {
          grid-template-columns: repeat(6, 1fr);
        }

        > hgroup {
          flex: 1;
          width: unset;
        }
      }
    }

    &_content {
      @include flex-column;
      flex: 1;
      gap: 24px;
    }

    &_recently,
    &_feed {
      .loading_fallback_container {
        background: transparent;
        position: relative;
        height: 200px;
      }
    }

    &_recently {
      border-radius: $corner-radius-primary;
      border: 1px solid $stroke-neutral-subtle;
      background: $fill-bg-card;
      padding: $padding-primary;

      > h2 {
        @include h3-semibold;
        color: $fill-neutral-default;
      }

      &_items {
        width: 100%;
        display: grid;
        gap: 16px;

        @media (min-width: $screen-lg) {
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto;
        }

        &_item {
          @include flex-row;
          align-items: center;
          gap: 20px;
          width: 100%;
          height: 120px;
          background-color: $fill-bg-screen;
          border-radius: $corner-radius-primary;
          padding: 16px;

          img {
            width: 80px;
            height: 80px;
            object-fit: cover;
            border-radius: $corner-radius-primary;
          }

          p {
            @include text-body-semibold;
            @include text-clamp(3);
            color: $content-default;
          }
        }
      }
    }

    &_feed {
      grid-column: 1 / 2;
      border-radius: $corner-radius-primary;
      border: 1px solid $stroke-neutral-subtle;
      background: $fill-bg-card;
      padding: $padding-primary;
      overflow: hidden;

      > h2 {
        @include h3-semibold;
        color: $fill-neutral-default;
      }

      &_items {
        @include flex-column;

        gap: 20px;
        > div {
          display: grid;
          row-gap: 5px;
          width: 100%;
          padding: $padding-primary;

          @media (min-width: $screen-lg) {
            grid-template-columns: 24px max-content 1fr auto;
            grid-template-rows: 1fr;
            column-gap: 5px;
          }

          > img {
            width: 24px;
            height: 24px;
            object-fit: cover;
          }

          a {
            @include text-body-semibold;
            color: $fill-neutral-press;
          }

          p {
            color: $content-description;
            @include text-body-normal;
          }

          p:first-of-type {
            display: flex;
            @include text-body-normal;

            flex: 2;
            @include text-clamp(10);
          }

          p:last-of-type {
            display: flex;
            @include text-body-medium;
          }
        }
      }

      &_no-items {
        @include flex-column;
        gap: 20px;
        width: 100%;

        @media (min-width: $screen-lg) {
          flex-direction: row;
        }

        > button {
          @include flex-column;
          gap: 10px;
          align-items: flex-start;
          border-radius: $corner-radius-primary;
          padding: 16px;

          &:hover {
            background: $fill-primary-subtle;
          }

          > h2 {
            margin: 0;
            @include text-body-semibold;
            color: $content-default;
          }

          > div {
            flex: 1;
            > p {
              text-align: left;
              @include text-body-small-normal;
              color: $content-description;
            }
          }

          > p {
            @include text-body-semibold;
            @include flex-row;
            color: $fill-neutral-press;
            text-align: left;
            align-items: center;
            gap: 20px;
          }
        }
      }
    }

    &_actions {
      display: none;

      @media (min-width: $screen-md) {
        @include flex-column;
        gap: 40px;
        width: 445px;
      }

      &_quick {
        border-radius: 8px;
        border: 1px solid $stroke-neutral-subtle;
        background: $fill-bg-card;
        padding: $padding-primary;

        > h2 {
          @include h3-semibold;
          color: $fill-neutral-default;
        }

        > div {
          @include flex-column;

          > button {
            @include flex-row;
            align-items: center;
            gap: 16px;

            height: 60px;
            padding: 8px;

            &:hover {
              border-radius: $corner-radius-primary;
              background: $fill-primary-subtle;
            }

            > div {
              @include flex-column;
              align-items: flex-start;
              p {
                @include text-body-semibold;
                color: $content-default;

                &:last-of-type {
                  @include text-body-small-normal;
                  color: $fill-neutral-hover;
                }
              }
            }
            svg {
              width: 32px;
              height: 32px;
              path {
                fill: $content-default;
              }
            }
          }
        }
      }
    }
  }
}
