@import '../../../global/styles/config';

.org-banner {
  @include flex-column;
  width: 100%;
  position: relative;
  gap: 12px;
  padding: 16px;
  z-index: 1;
  overflow: hidden;
  display: grid;
  grid-template-columns: 116px 1fr;
  grid-template-rows: 50px 50px;
  border-radius: 8px;
  border: 1px solid $stroke-neutral-subtle;
  background: $fill-bg-card;

  > img {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  > h1 {
    @include h2-semibold;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    color: $content-default;
  }

  &__stats {
    .statistics-container {
      > hgroup {
        align-items: flex-start;
        > h2 {
          @include text-body-small-normal;
          color: $content-description;
          margin-bottom: 0px;
        }

        > h3 {
          @include h4-bold;
          color: $fill-neutral-selected;
        }
      }
    }
  }
}
