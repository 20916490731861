@import '../../../global/styles/config';

.profile_settings_page {
  @include flex-column;
  flex: 1;
  padding: 0 15px 15px;

  &__back {
    display: none;
  }

  &__main {
    .aside-popup-menu__menu {
      .aside-menu {
        @media (min-width: $screen-md) {
          border-radius: $corner-radius-tertiary;
          background: $fill-bg-card;
        }
      }
    }
  }

  @media only screen and (min-width: $screen-md) {
    &__main {
      @include flex-row;

      &_section {
        flex: 1;
        padding: 30px;
        margin-left: 15px;
        border-radius: $corner-radius-tertiary;
        background: $fill-bg-card;
      }
    }
  }

  @media screen and (min-width: $screen-xl) {
    background: none;
    background-size: cover;
    background-position: center;

    > * {
      width: $layout-width;
      margin: 0 auto;
    }

    &__main {
      border-radius: $corner-radius-tertiary;
      overflow: hidden;
      flex: 1;
    }
  }
}
