@import '../../global/styles/config';

.top-up-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 24px;

  &_item {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    border-radius: $corner-radius-primary;
    border: 1px solid $stroke-bg-card;
    background: $fill-bg-card;
    padding: $padding-primary;
    box-sizing: border-box;

    @media screen and (max-width: $screen-md) {
      padding: 12px;
      @include flex-column;
      gap: 8px;
    }

    &_info {
      width: 50%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      p {
        @include text-body-medium;
        color: $content-default;
      }

      @media screen and (max-width: $screen-md) {
        grid-template-columns: 4fr 1fr;
        gap: 16px;
        width: 100%;
        gap: 8px;

        > p.amount {
          order: 1;
        }

        > div {
          order: 2;
        }

        > p:last-of-type {
          order: 3;
        }
      }

      > p.amount {
        @include text-body-semibold;
      }
    }

    > p {
      @include text-body-medium;
      color: $content-default;
    }
  }
}
