@import '../../global/styles/config';

.editable_image {
  @include flex-column;
  @include center;
  position: relative;

  label {
    @include text-body-semibold;
    color: $content-default;
    margin-bottom: 10px;
  }

  &__image {
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: $corner-radius-primary;
    @media (min-width: $screen-md) {
      width: 120px;
      height: 120px;
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__edit {
    @include flex-column;
    @include center;
    position: absolute;
    bottom: -5px;
    right: 0px;
    width: 25px;
    height: 25px;
    background: black;
    border: 1px solid $content-primary;
    border-radius: $corner-radius-round;
    cursor: pointer;

    @media (min-width: $screen-md) {
      width: 30px;
      height: 30px;
    }

    > svg {
      width: 25px;
      height: 15px;

      @media (min-width: $screen-md) {
        width: 30px;
        height: 20px;
      }
    }

    > input {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
    }
  }
}
