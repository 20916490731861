@import '../../../global/styles/config';

.forgot-password-page {
  @include flex-column;
  align-items: center;
  flex: 1;
  padding: 24px;
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: unset;
    left: -400px;
    bottom: -400px;
    width: 1046px;
    height: 612px;
    background-clip: border-box;

    border-radius: 1046px;
    background: radial-gradient(
      767.85% 113.99% at 64.21% 50%,
      rgba(87, 0, 251, 0) 0%,
      rgba(255, 60, 89, 0.2) 52.84%,
      rgba(122, 53, 255, 0) 100%
    );
    filter: blur(100px);
    z-index: 0;
  }

  &::after {
    content: '';
    position: absolute;
    top: -300px;
    right: -400px;
    width: 809.65px;
    height: 713px;
    border-radius: 809.65px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(102, 7, 255, 0.2) 0%, rgba(111, 0, 252, 0) 100%);
  }

  &__logo {
    align-self: center;
    padding: 16px 0 40px;
    z-index: 1;

    > img {
      width: 150px;

      @media (min-width: $screen-lg) {
        width: 350px;
      }
    }
  }
}

.enter-email,
.new-password {
  @include flex-column;
  flex: 1;
  gap: 10px;
  padding: 15px;
  width: 100%;
  border-radius: $corner-radius-tertiary;
  border: 1px solid $stroke-bg-card;
  background: $fill-bg-card;

  /* shadow-bottom-300 */
  box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.1), 0px 20px 32px -24px rgba(0, 0, 0, 0.1);
  padding: 16px;
  z-index: 1;

  @media only screen and (min-width: $screen-md) {
    width: 460px;
    flex: unset;
    min-height: 500px;
  }

  > h1 {
    @include h4-semibold;
    text-align: center;
    margin-bottom: 20px;
  }

  > button,
  a {
    @include text-body-semibold;
    margin-top: 10px;
    align-self: center;
    width: 200px;
    height: 40px;
  }

  > button {
    height: 56px;
  }
}

.enter-email {
  margin-bottom: 80px;
  > h1 {
    text-align: center;
    margin-bottom: 5px;
  }
  > h2 {
    @include label-medium;
    text-align: center;
    margin-bottom: 20px;

    > a {
      @include label-medium;
      margin-left: 5px;
      color: $content-primary;
      font-weight: $font-weight-bold;
    }
  }

  > button {
    height: 56px;
  }
}

.new-password {
  > h2 {
    @include text-body-semibold;
    text-align: center;
  }

  > h3 {
    @include text-body-small-normal;
    color: $content-negative;
    text-align: center;
  }

  &__email {
    @include label-medium;
    color: $content-default;
    text-align: center;

    @media (min-width: $screen-lg) {
      text-align: left;
    }
  }

  &__resend-otp {
    @include label-medium;
    text-align: center;

    > p {
      @include label-medium;
      color: $content-default;
    }

    @media (min-width: $screen-lg) {
      @include flex-row;
      text-align: left;
      gap: 10px;
      align-items: center;
    }

    > button {
      @include label-medium;
      color: $content-primary;
      margin-top: 10px;
      @media (min-width: $screen-lg) {
        margin-top: 0;
      }
    }
  }

  &__back {
    @include label-medium;
    margin-bottom: 25px;
    text-align: center;

    @media (min-width: $screen-lg) {
      text-align: left;
    }

    > button {
      @include label-medium;
      color: $content-primary;
      margin-left: 5px;
    }
  }
}
