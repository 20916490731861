@import '../../../global/styles/config';

.organization-games-page {
  @include flex-column;
  flex: 1;
  padding: 24px 0;

  &__heading {
    @include flex-column;
    gap: 16px;

    @media (min-width: $screen-md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    @media (min-width: $screen-xl) {
      padding: 16px;
    }

    &_title {
      @include flex-column;
      gap: 8px;

      > h1 {
        @include h2-semibold;

        span {
          @include h2-normal;
          color: $content-description;
        }
      }
    }

    &_new {
      height: 36px;

      @media (min-width: $screen-md) {
        width: 165px;
      }
    }
  }

  &__content {
    @include flex-column;
    position: relative;
    border-radius: 8px;

    @media (min-width: $screen-xl) {
      margin-top: 24px;
      padding: 0 15px;
    }

    &_actions {
      display: grid;
      gap: 15px;
      grid-template-columns: 1fr 120px;
      margin: 24px 0;

      @media (min-width: $screen-md) {
        grid-template-columns: 410px 150px;
        justify-content: space-between;
      }

      .search-container {
        grid-column: 1 / 2;
      }

      .select {
        background-color: transparent;
      }
    }

    &_games {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      justify-items: center;
      gap: 24px;

      .loading_fallback_container {
        height: 300px;
        top: 70px;
      }
    }
  }
}
