@import '../../../global/styles/config';
.create-campaign-modal {
  width: 100%;
  height: 100%;
  display: none;

  @media (min-width: $screen-lg) {
    display: grid;
    grid-template-columns: 360px 1fr;
    grid-template-rows: 64px 1fr;
  }

  &__heading {
    grid-column: 1 / 3;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    border-bottom: 2px solid $fill-neutral-subtle;
    @include flex-row;

    h1 {
      @include h4-semibold;
      color: $content-default;
      /*TODO: Remove this once we have a new font*/
      font-family: 'Barlow', 'sans-serif';
    }

    button {
      @include flex-row;
      align-items: center;
      gap: 4px;

      svg {
        path {
          stroke: $content-default;
        }
      }
    }
  }

  &__main {
    @include flex-column;
    grid-column: 2 / 3;
    margin: 12px 40px 12px 0px;
    border-radius: $corner-radius-tertiary;
    background: $fill-bg-card;
    overflow: hidden;
  }

  &__main_footer {
    @include flex-row;
    justify-content: flex-end;
    padding: $gap-primary $gap-secondary;
    border-top: 1px solid $stroke-neutral-subtle;
    background: $fill-bg-card;

    > div {
      &:first-child {
        flex: 1;
        > button {
          width: 140px;
          height: 48px;
          padding: $padding-quinary;
        }
      }

      &:last-child {
        @include flex-row;
        gap: $gap-primary;

        > button {
          width: 140px;
          height: 48px;
          padding: $padding-quinary;
        }
      }
    }
  }
}
