@import '../../../global/styles/config';

.organization-game-page {
  @include flex-column;
  flex: 1;
  width: 100%;

  &__navigation {
    width: 100%;
    box-sizing: border-box;
    background-color: $neutral-white;
    padding: $padding-secondary;
    padding-bottom: 0;
    border-radius: 16px 16px 0 0;
    margin-top: $gap-secondary;

    &__menu {
      @include flex-row;
      align-items: flex-end;
      gap: $gap-secondary;
      border-bottom: 1px solid $stroke-neutral-subtle;
      width: 100%;
      > a {
        text-align: center;
        width: auto;
        padding-bottom: 8px;
        color: $content-default;
      }

      .active-page {
        font-weight: $font-weight-bold;
        border-bottom: 2px solid $stroke-neutral-selected;
      }
    }
  }

  &__main {
    background-color: $neutral-white;
    border-radius: 0 0 16px 16px;
    padding: 0 $padding-secondary;

    .ad-surface-list {
      &__heading {
        &_title {
          > button {
            display: none;
          }
        }
      }
    }

    .aside-menu {
      &__desktop {
        padding-left: 0;
      }
    }
  }

  @media screen and (min-width: $screen-md) {
    &__back {
      display: block;
      margin: 30px 0;
      > a {
        @include flex-row;
        align-items: center;

        p {
          @include text-body-small-semibold;
          color: $neutral-white;
        }
      }
    }
  }
}
