@import '../../../global/styles/config';
.manage-organization-page {
  @include flex-column;
  flex: 1;
  padding: 0 15px 15px;

  @media screen and (min-width: $screen-xl) {
    margin: 0 auto;
  }

  &__section {
    width: 100%;

    @media (min-width: $screen-xl) {
      margin-top: 20px;
      padding: 0 25px;
      width: $layout-width;
      flex: 1;
    }

    .loading_fallback_container {
      position: relative;
      height: 200px;
      background: transparent;
    }

    &_heading {
      @include flex-column;
      margin-top: 10px;
      gap: 10px;

      > h1 {
        @include h2-semibold;
      }

      > button {
        height: 40px;

        > p {
          color: $fixed-inverse;
        }

        @media (min-width: $screen-md) {
          width: 220px;
          margin-left: auto;
        }
      }
    }

    &_main {
      width: 100%;
      padding: 25px 0;

      &_empty {
        @include flex-column;
        align-items: center;
        margin-top: 30px;
      }

      &_organization {
        @include flex-column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 100%;
        height: 180px;
        background-color: $fill-primary-subtle;
        border-radius: $corner-radius-primary;
        padding: 25px;

        @media (min-width: $screen-md) {
          flex-direction: row;
          height: 165px;
        }

        &_heading {
          @include flex-row;
          align-items: center;
          gap: 10px;
          width: 100%;

          > img {
            width: 75px;
            height: 75px;
            object-fit: cover;

            @media (min-width: $screen-lg) {
              width: 120px;
              height: 120px;
            }
          }

          > div {
            > h2 {
              @include text-body-semibold;
              @include text-clamp(3);
              margin-bottom: 5px;
              word-break: break-all;
            }
            > p {
              @include text-body-normal;
              @include text-clamp(3);
              color: $content-description;
              word-break: break-all;
            }
          }
        }

        &_actions {
          @include flex-row;
          width: 100%;
          gap: 10px;

          @media (min-width: $screen-md) {
            justify-content: flex-end;
            width: 200px;
          }

          > button {
            flex: 1;
            height: 30px;

            @media (min-width: $screen-md) {
              flex: unset;
              width: 140px;
            }
          }

          .btn-primary-solid {
            @include flex-row;
            align-items: center;
            gap: 5px;

            > p {
              @include text-body-semibold;
              color: $fixed-inverse;
            }
            svg {
              path {
                stroke: $neutral-white;
              }
            }
          }

          .btn-neutral-ghost {
            @include flex-row;
            align-items: center;
            gap: 5px;
            > p {
              @include text-body-semibold;
              color: $fixed-inverse;
              color: $content-default;
            }
            svg {
              path {
                stroke: $content-default;
              }
            }
          }
        }
      }
    }
  }
}
