@import '../../../global/styles/config';
.verify-user-page {
  @include flex-column;
  flex: 1;
  gap: 20px;
  padding: 15px;
  text-align: center;
  position: relative;
  min-height: 100vh;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: unset;
    left: -400px;
    bottom: -400px;
    width: 1046px;
    height: 612px;
    background-clip: border-box;

    border-radius: 1046px;
    background: radial-gradient(
      767.85% 113.99% at 64.21% 50%,
      rgba(87, 0, 251, 0) 0%,
      rgba(255, 60, 89, 0.2) 52.84%,
      rgba(122, 53, 255, 0) 100%
    );
    filter: blur(100px);
    z-index: 0;
  }

  &::after {
    content: '';
    position: absolute;
    top: -300px;
    right: -400px;
    width: 809.65px;
    height: 713px;
    border-radius: 809.65px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(102, 7, 255, 0.2) 0%, rgba(111, 0, 252, 0) 100%);
  }

  &__logo {
    align-self: center;
    padding: 30px 0;
    z-index: 1;

    > img {
      width: 150px;

      @media (min-width: $screen-lg) {
        width: 350px;
      }
    }
  }

  &__form {
    @include flex-column;
    align-self: center;
    flex: 1;
    width: 100%;
    max-width: 450px;
    padding: 20px;
    margin-bottom: 80px;
    border-radius: $corner-radius-tertiary;
    border: 1px solid $stroke-bg-card;
    background: $fill-bg-card;

    /* shadow-bottom-300 */
    box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.1), 0px 20px 32px -24px rgba(0, 0, 0, 0.1);
    gap: 20px;
    position: relative;

    @media (min-width: $screen-lg) {
      max-width: 520px;
    }

    > h1 {
      @include h4-semibold;
      text-align: center;
    }

    > h2 {
      @include text-body-small-normal;
      color: $content-description;
    }

    > h3 {
      @include label-medium;
      color: $content-negative;
    }

    .otp-group {
      margin-top: 20px;
    }

    > button {
      align-self: center;
      width: 200px;
      height: 40px;
    }

    &_resend {
      @media (min-width: $screen-md) {
        @include flex-row;
        @include center;
        gap: 5px;
      }
      > p {
        @include label-medium;
        color: $content-description;
      }

      > button {
        @include label-medium;
        color: $content-primary;
      }
    }

    &_back {
      @include label-medium;
      color: $content-description;

      > button {
        @include label-medium;
        color: $content-primary;
        margin-left: 5px;
      }
    }
  }
}
