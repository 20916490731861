@import '../../../../../global/styles/config';

@mixin key-box {
  > div {
    &:first-of-type {
      @include flex-row;
      gap: 10px;
      > label {
        @include text-body-small-semibold;
        color: $content-default;
      }
    }

    &:last-of-type {
      @include flex-row;
      gap: 10px;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;

      @media (min-width: $screen-lg) {
        margin: 0;
      }

      > p {
        @include text-body-small-semibold;
        word-break: break-all;
      }

      > button {
        @include flex-column;
        @include center;
      }
    }
  }
}

.api-credentials {
  @include flex-column;
  padding: 25px 0;
  gap: 25px;

  @media (min-width: $screen-lg) {
    padding: 0;
  }

  &__heading {
    @include flex-column;
    gap: 25px;

    @media (min-width: $screen-md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    > h2 {
      @include text-body-semibold;
    }

    > button {
      @include flex-row;
      align-items: center;
      height: 48px;
      width: max-content;
      gap: $gap-content;

      > svg {
        > path {
          stroke: $primary-500;
        }
      }

      @media (min-width: $screen-md) {
        width: 250px;
      }
    }
  }

  > h3 {
    @include text-body-normal;
    color: $content-default;
    > span {
      font-weight: $font-weight-bold;
      word-break: break-all;
    }
  }

  &__sdk-licence {
    background-color: $neutral-white;
    padding: $padding-primary;
    border-radius: $corner-radius-tertiary;

    &_header {
      @include flex-row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      > h2 {
        @include text-body-semibold;
      }
      > button {
        display: flex;
      }
    }

    &_main {
      .checkbox-container {
        margin: 24px 0;

        > p {
          flex: 1;
        }
      }
    }

    &_actions {
      @include flex-row;
      @include center;
      gap: 10px;
      padding-top: 15px;
      > button {
        width: 150px;
        height: 40px;
      }

      @media (min-width: $screen-md) {
        justify-content: flex-end;
      }
    }
  }

  &__keys {
    @include flex-row;
    align-items: center;
    height: auto;
    width: 100%;
    gap: $gap-primary;
    padding: $padding-secondary;
    border: 1px solid $stroke-bg-card;
    border-radius: $corner-radius-primary;

    p {
      color: $content-description;
    }

    > label {
      font-weight: $font-weight-bold;
    }

    &_key,
    &_secret {
      @include key-box;
      @media (min-width: $screen-lg) {
        @include flex-row;
        justify-content: space-between;
        align-items: center;
      }
    }

    &_key {
      &_expired,
      &_active {
        @include flex-row;
        @include center;
        width: 100px;
        height: 24px;
        border-radius: $corner-radius-secondary;
        color: $content-default;
        background-color: $black-50;
        border: 1px solid $stroke-neutral-medium;
      }

      &_active {
        color: $neutral-white;
        background-color: $primary-500;
      }
    }

    button.delete-btn {
      margin-left: auto;
    }
  }

  &__docs {
    @include text-body-small-normal;
    color: $content-default;
    > a {
      color: $content-primary;
      font-weight: $font-weight-bold;
      text-decoration: underline;
    }
  }
}
