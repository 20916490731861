@import '../../../global/styles/config';

.first-time-user-page {
  @include flex-column;
  gap: 40px;
  padding: 15px;
  position: relative;
  overflow: hidden;
  min-height: 100vh;

  &::before {
    content: '';
    position: absolute;
    top: unset;
    left: -400px;
    bottom: -400px;
    width: 1046px;
    height: 612px;
    background-clip: border-box;

    border-radius: 1046px;
    background: radial-gradient(
      767.85% 113.99% at 64.21% 50%,
      rgba(87, 0, 251, 0) 0%,
      rgba(255, 60, 89, 0.2) 52.84%,
      rgba(122, 53, 255, 0) 100%
    );
    filter: blur(100px);
    z-index: 0;
  }

  &::after {
    content: '';
    position: absolute;
    top: -300px;
    right: -400px;
    width: 809.65px;
    height: 713px;
    border-radius: 809.65px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(102, 7, 255, 0.2) 0%, rgba(111, 0, 252, 0) 100%);
  }

  &__logo {
    align-self: center;
    padding-top: 30px;
    z-index: 1;

    > img {
      width: 150px;

      @media (min-width: $screen-lg) {
        width: 350px;
      }
    }
  }

  &__form,
  &__path {
    padding: 0 24px;
    border-radius: 16px;
    padding: 16px;
    z-index: 1;
    width: 100%;
    max-width: 450px;
    align-self: center;

    border-radius: $corner-radius-tertiary;
    border: 1px solid $stroke-bg-card;
    background: $fill-bg-card;

    /* shadow-bottom-300 */
    box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.1), 0px 20px 32px -24px rgba(0, 0, 0, 0.1);

    @media (min-width: $screen-lg) {
      max-width: 585px;
    }

    &_heading {
      margin-bottom: 40px;
      > h1 {
        @include h4-semibold;
        text-align: center;
      }
    }

    .input_container {
      > input {
        @media (min-width: $screen-lg) {
          height: 56px;
        }
      }
    }

    .textarea-container {
      > textarea {
        height: 200px;
      }
    }

    .select {
      background-color: transparent;

      @media (min-width: $screen-lg) {
        height: 56px;
      }

      &__options {
        > button {
          height: unset;
        }
      }
      &__org-type {
        padding: 8px;

        > p {
          &:last-of-type {
            @include text-caption-normal;
            color: $content-description;
          }
        }
      }
    }

    .file-upload-container {
      margin-top: 20px;

      &__input {
        width: 200px;
        height: 200px;
      }

      > p {
        text-align: left;
      }
    }

    &_actions {
      @include flex-row;
      @include center;
      gap: 10px;
      padding-top: 30px;
      > button {
        width: 150px;
        height: 40px;

        @media (min-width: $screen-lg) {
          width: 256px;
          height: 56px;
        }
      }
    }
  }

  &__path {
    > h1 {
      @include h4-semibold;
      text-align: center;
    }

    > h2 {
      @include text-body-small-normal;
      color: $content-description;
      margin-top: 8px;
      text-align: center;
    }

    &_item {
      @include flex-row;
      align-items: center;
      gap: 10px;
      padding: 8px;
      border-radius: $corner-radius-primary;
      background: $fill-bg-screen;
      margin-top: 16px;

      > img {
        width: 163px;
        height: 83px;
      }

      > p {
        @include text-body-semibold;
        color: $content-default;
      }
    }

    &_actions {
      margin-top: 20px;
      @include flex-column;
      align-items: center;

      @media (min-width: $screen-lg) {
        @include flex-row;
      }

      gap: 24px;
      > button {
        width: 100%;
        height: 40px;
        padding: 0 16px;

        @media (min-width: $screen-lg) {
          width: max-content;
        }
      }
    }
  }
}
