@import '../../global/styles/config';

.aside-menu {
  @include flex-column;

  &__desktop {
    display: none;
    padding: 10px 0;
    flex: 1;

    > button {
      @include flex-row;
      @include center;
      gap: 10px;
      height: 50px;
      width: 100%;
      border-radius: $corner-radius-primary;

      > p {
        @include text-body-semibold;
        color: $content-default;
      }

      > div {
        @include flex-row;
        @include center;
        background-color: $fill-primary-subtle-hover;
        border-radius: $corner-radius-round;
        width: 32px;
        height: 32px;
      }
    }

    .active {
      background-color: $fill-primary-subtle;

      > p {
        @include text-body-semibold;
        color: $content-primary;
      }
    }

    @media screen and (min-width: $screen-md) {
      @include flex-column;
      width: 260px;
      padding: 0 15px;
      gap: 10px;
      padding-top: 15px;

      > button {
        padding: 0 10px;
        justify-content: flex-start;
        gap: 10px;
      }
    }

    @media only screen and (min-width: $screen-xl) {
      > button {
        height: 40px;
      }
    }
  }
}
