@import '../../../global/styles/config';

.new-ad-surface-video-modal {
  width: 500px;
  background: $fill-bg-screen !important;
  color: $gray-900;
  overflow: auto;

  &__heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    > h2 {
      color: $gray-900;
      span {
        text-transform: lowercase;
      }
    }
  }
  &__body {
    @include flex-column;

    > div {
      > label {
        margin-bottom: 5px;
        font-weight: $font-weight-bold;
      }
    }
  }
  &__actions {
    @include flex-row;
    justify-content: flex-end;
    gap: 10px;
    padding-top: 20px;

    button {
      width: 150px;
      height: 40px;
    }
  }
}
