@import '../../global/styles/config';
.otp-group {
  @include flex-row;
  justify-content: center;
  width: 100%;
  column-gap: 10px;
  > input {
    width: 40px;
    height: 40px;
    border: 1px solid $content-description;
    border-radius: 5px;
    background: transparent;
    padding: 0;
    text-align: center;
    line-height: 1;

    &:focus {
      outline: none;
      border: 1px solid $content-default;
    }
  }
}
