@import '../../global/styles/config';

.lds-ring {
  display: flex;
  align-items: center;
  position: relative;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  margin: 8px;
  border: 5px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $content-default transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lg {
  width: 72px;
  height: 72px;
  div {
    width: 62px;
    height: 62px;
  }
}

.md {
  width: 42px;
  height: 42px;
  div {
    width: 32px;
    height: 32px;
  }
}

.sm {
  width: 32px;
  height: 32px;
  div {
    width: 22px;
    height: 22px;
  }
}

.white {
  > div {
    border-color: $neutral-white transparent transparent;
  }
}

.primary {
  > div {
    border-color: $primary-500 transparent transparent;
  }
}
