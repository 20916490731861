@import '../../../global/styles/config';

.update-message {
  position: fixed;
  @include flex-row;
  justify-content: flex-end;
  min-width: 360px;
  z-index: 1;
  bottom: 60px;
  border-radius: $corner-radius-primary;
  border: 1px solid $content-positive-intense;
  background-color: $content-positive-intense;
  padding: 8px 16px;
  transform: translate(-50%, 250%);
  left: 50%;
  animation: moveup 0.5s ease forwards, movedown 0.5s 8s ease forwards;

  @media (min-width: $screen-md) {
    bottom: 20px;
  }

  @media (min-width: $screen-xl) {
    transform: translate(-130%, 250%);
    animation: moveupLarge 0.5s ease forwards, movedownLarge 0.5s 8s ease forwards;
  }

  > div {
    @include flex-row;
    align-items: center;
    gap: 20px;
    width: 100%;
    z-index: 3;

    @media (min-width: $screen-md) {
      width: 360px;
    }

    > p {
      @include text-body-small-normal;
      color: $content-positive-intense;
    }
  }

  button {
    @include flex-row;
    align-items: center;

    svg {
      path {
        stroke: $content-positive-intense;
      }
    }
  }
}

@keyframes moveup {
  from {
    transform: translate(-50%, 250%);
  }
  to {
    transform: translate(-50%, -50%);
  }
}

@keyframes movedown {
  from {
    transform: translate(-50%, -50%);
  }
  to {
    transform: translate(-50%, 250%);
  }
}
@keyframes moveupLarge {
  from {
    transform: translate(-130%, 250%);
  }
  to {
    transform: translate(-130%, -50%);
  }
}

@keyframes movedownLarge {
  from {
    transform: translate(-130%, -50%);
  }
  to {
    transform: translate(-130%, 250%);
  }
}
