@import '../../global/styles/config/';

[class*='icon-bg'] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.icon-bg-primary {
  background-color: rgba($fill-primary-default, 0.5);
  > svg > path {
    fill: $fill-primary-intense;
  }
}

.icon-bg-danger {
  background-color: rgba($negative-500, 0.5);
  > svg > path {
    fill: $fill-negative-intense;
  }
}
