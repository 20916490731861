@import '../../../global/styles/config';

.create-offer-modal {
  width: 500px;
  &__heading {
    @include flex-row;
    justify-content: space-between;
    align-items: center;

    h2 {
      @include h4-semibold;
      color: $content-default;
    }

    button {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  > h3 {
    @include text-body-small-normal;
    color: $content-description;
    margin-bottom: $gap-primary;
  }

  &__content {
    &_cta {
      @include flex-row;
      margin-top: $gap-primary;
      gap: 12px;

      > div {
        flex: 1;
      }
    }
  }

  &__footer {
    @include flex-row;
    justify-content: space-between;
    margin-top: $gap-primary;

    button {
      height: 40px;
      padding: 0 $padding-primary;
    }

    &-right {
      @include flex-row;
      gap: 12px;
    }
  }
}
