@import '../../global/styles/config';

.date-picker {
  position: relative;

  &__select {
    @include flex-row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding: 8px 16px;
    border-bottom: 1px solid $stroke-neutral-subtle;
    > p {
      @include text-body-medium;
      color: $content-default;
    }
  }

  &__label {
    @include text-body-semibold;
    color: $content-description;
    margin-bottom: 4px;
    display: block;
  }

  .calendar {
    position: absolute;
  }
}
.calendar {
  border-radius: $corner-radius-tertiary;
  border: 1px solid $stroke-bg-card;
  background-color: $fill-bg-card;
  width: 100%;
  top: 40px;
  left: 0;

  &__heading {
    @include flex-row;
    justify-content: center;

    gap: 10px;

    > div {
      &:first-child {
        width: 140px;
      }

      &:last-child {
        width: 80px;
      }
    }

    .select_container {
      .select {
        border-radius: unset;
        margin: 16px 0;
        background-color: $content-description;
      }
    }
  }

  &__main {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;

    > div {
      height: 35px;
      color: $content-default;
      @include flex-row;
      align-items: center;
      justify-content: center;

      > button {
        width: 100%;
        height: 100%;
        time {
          color: $content-default;
        }
      }
      .not-current {
        time {
          color: $content-description;
        }
      }
    }

    .today {
      border: 1px solid $content-primary;
      border-radius: $corner-radius-round;
    }

    .selected-day {
      background-color: $content-primary;
      border-radius: $corner-radius-round;

      > button {
        > time {
          color: $fixed-inverse;
        }
      }
    }
  }
}
