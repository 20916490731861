@import '../../global/styles/config';

.small-error-msg {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: $gap-content;
  min-height: 16px;
  margin: 5px 0;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: $content-negative;
    }
  }

  p {
    @include text-caption-normal;
    color: $content-negative;
  }
}
