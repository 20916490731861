@import '../../../global/styles/config';

.invite-member-modal {
  width: 100%;

  &__header {
    @include flex-row;
    justify-content: space-between;
    margin-bottom: 20px;
    > h2 {
      @include h4-semibold;
      color: $content-default;
    }
  }
}

.confirm-invite-member {
  @include flex-column;
  gap: 10px;
  .user {
    justify-content: center;
  }

  > p {
    @include text-body-small-normal;
    color: $content-description;
    text-align: center;
  }

  &__actions {
    @include flex-row;
    @include center;
    gap: 10px;
    padding-top: 15px;
    > button {
      width: 150px;
      height: 40px;
    }
  }
}

.member-invited {
  width: 450px;
  @include flex-column;
  background-color: $fill-bg-card;
  border: 1px solid $stroke-bg-card;
  border-radius: $corner-radius-primary;
  gap: 20px;
  align-items: center;

  &__header {
    @include flex-row;
    width: 100%;
    justify-content: flex-end;
    > h2 {
      @include text-body-medium;
      color: $content-default;
    }
  }
  > p {
    @include text-body-normal;
    color: $content-default;
    text-align: center;
    span {
      font-weight: $font-weight-bold;
      color: $content-default;
      margin-right: 5px;
    }
  }

  > button {
    width: 100%;
    height: 40px;
  }
}
