@import '../../global/styles/config';

.small-dashboard-bar {
  cursor: pointer;
  padding: 15px;
  z-index: 1;

  @include flex-column;
  justify-content: center;
  gap: 15px;
  position: relative;
  border-radius: $corner-radius-primary;
  overflow: hidden;

  &__info {
    @include flex-row;
    gap: 15px;

    video {
      height: 90px;
      width: 90px;
      border-radius: $corner-radius-primary;
      object-fit: cover;

      @media (min-width: $screen-xl) {
        height: 100px;
        width: 100px;
      }
    }

    > div {
      @include flex-column;
      justify-content: center;
      &:first-of-type {
        img {
          border-radius: $corner-radius-primary;
          height: 40px;
          width: 40px;
          object-fit: cover;

          @media (min-width: $screen-xl) {
            height: 56px;
            width: 56px;
          }
        }
      }

      &:last-of-type {
        h2 {
          @include text-body-small-normal;
          @include text-clamp(1);

          @media (min-width: $screen-xl) {
            @include text-body-semibold;
          }
        }

        h3 {
          @include text-caption-normal;
          @include text-clamp(1);

          @media (min-width: $screen-xl) {
            @include text-body-small-normal;
          }
        }
      }
    }
  }

  &__stats {
    @include flex-row;

    .statistics-container {
      flex: 1;

      > hgroup {
        gap: 5px;
      }
    }
  }

  @media (min-width: $screen-md) {
    @include flex-row;
    align-items: center;
    justify-content: space-between;
    height: 120px;
  }
}
