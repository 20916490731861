@import '../../global/styles/config';

.file-upload-container {
  > label {
    @include text-body-semibold;
    display: block;
    color: $content-description;
    margin-bottom: 5px;
  }

  .file-upload-container__input {
    @include flex-column;
    @include center;
    height: 100px;
    border-radius: $corner-radius-primary;
    border-color: $stroke-neutral-medium;
    border-width: 1px;
    border-style: dashed;
    position: relative;
    padding: 5px;
    overflow: hidden;

    > input {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
      z-index: 1;
    }

    > svg {
      width: 30px;
      height: 30px;
      margin-bottom: 5px;
    }

    > p {
      @include text-body-small-semibold;
      color: $content-default;
      text-align: center;
    }
  }

  > p {
    @include text-caption-normal;
    color: $content-negative;
    margin-top: 5px;
    text-align: right;
    min-height: 16px;
  }

  &__preview {
    @include flex-row;
    position: absolute;
    height: 100%;
    > img {
      height: 100%;
    }
    .files {
      @include flex-column;
      @include center;

      > p {
        @include text-clamp(1);
        width: 90%;
      }
    }
  }
}
