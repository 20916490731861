@import '../../../global/styles/config';

.organization-campaigns-page {
  @include flex-column;
  flex: 1;
  padding: 24px 0;

  &__heading {
    @include flex-column;
    gap: 16px;

    &_title {
      @include flex-row;
      justify-content: space-between;
      gap: 8px;

      > h1 {
        @include h2-semibold;
        color: $content-default;
      }

      > a {
        @include flex-row;
        align-items: center;
        @include label-small;
        color: $content-primary;

        svg:last-child {
          path {
            stroke: $content-default;
          }
        }
      }
    }

    &_actions {
      @include flex-column;
      flex-direction: column-reverse;
      gap: 24px;
      margin-bottom: 24px;

      @media (min-width: $screen-md) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .search-container {
        @media (min-width: $screen-md) {
          max-width: 410px;
        }
      }

      &_new {
        height: 48px;
        padding: 0 $padding-quaternary;

        p {
          @include label-medium;
          color: $fixed-inverse;
        }
      }
    }
  }

  &__content {
    @include flex-column;
    position: relative;

    margin-top: 8px;

    &_campaigns {
      .loading_fallback_container {
        height: 300px;
        top: 70px;
        background-color: transparent;
      }
    }
  }
}
