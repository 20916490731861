@import '../../../global/styles/config';

.remove-member-modal {
  width: 350px;
  &__heading {
    @include flex-row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 15px;

    > button {
      display: flex;
    }
  }

  > p {
    @include text-body-semibold;
    color: $content-default;
    text-align: center;
  }

  .user {
    justify-content: center;
  }

  &__actions {
    @include flex-row;
    @include center;
    gap: 10px;
    padding-top: 15px;
    > button {
      width: 150px;
      height: 40px;
    }
  }
}
