@import '../../global/styles/config';

.textarea-container {
  @include flex-column;
  > label {
    @include text-body-semibold;
    color: $content-description;
    margin-bottom: $gap-tertiary;
  }

  > textarea {
    @include text-body-medium;
    color: $content-default;
    width: 100%;
    height: 100px;
    border-bottom: 1px solid $stroke-neutral-subtle;
    border-left: none;
    border-right: none;
    border-top: none;
    padding: 5px 10px;
    background: none;
    &:focus {
      outline: none;
    }
    &::placeholder {
      @include text-body-medium;
      color: $content-subtle;
      //TODO ugly fix to vertically center placeholder text (find better solution)
      transform: translateY(2px);
    }

    &:disabled {
      color: $content-disable;
      background: $fill-neutral-disable;
    }
  }

  > div {
    min-height: 16px;
    display: grid;
    grid-template-areas: 'a b';
    > p {
      @include text-caption-normal;
      margin-top: 5px;
    }
  }

  &__counter {
    grid-area: a;
    color: $content-description;
  }

  &__error {
    grid-area: b;
    justify-self: self-end;
    color: $content-negative;
    min-height: 16px;
  }
}
