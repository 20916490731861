@import '../../../global/styles/config';

.modal {
  > .new-branded-object-modal {
    background: $fill-bg-card;
    padding: $padding-secondary;
    width: 100%;
    position: relative;
    overflow-y: auto;

    @media screen and (min-width: $screen-md) {
      width: 500px;
    }

    > .new-branded-object-modal__heading {
      @include flex-row;
      justify-content: space-between;
      align-items: center;

      > h2 {
        color: $content-default;
        font-size: 20px;
      }

      > button#close-button {
        position: absolute;
        top: $padding-primary;
        right: $padding-primary;
      }
      margin-bottom: $gap-secondary;
    }
    form {
      @include flex-column;
      gap: $gap-primary;

      button[type='submit'] {
        height: 40px;
        width: max-content;
        font-weight: 400;
        padding: 0 $padding-secondary;
        margin-left: auto;

        cursor: default;

        &:not(:disabled) {
          &:hover {
            cursor: pointer;
            opacity: 0.8;
          }

          &:active {
            opacity: 0.6;
          }
        }
      }
    }
  }
}
