@import '../../../global/styles/config';
.delete_modal {
  @include flex-column;
  position: relative;
  width: 100%;
  padding: $padding-secondary;
  gap: $padding-primary;
  color: $content-default;
  border-radius: $corner-radius-primary;
  border: 1px solid $stroke-bg-card;
  background: $fill-bg-card;

  &__heading {
    position: absolute;
    top: $padding-secondary;
    right: $padding-secondary;
  }

  &__actions {
    @include flex-row;
    width: 100%;
    justify-content: flex-end;
    gap: $gap-tertiary;
    margin-top: $padding-primary;
    > button {
      height: 40px;
    }
  }

  @media screen and (min-width: $screen-md) {
    width: 400px;
  }
}
