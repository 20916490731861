@import '../../../../../global/styles/config';

.campaign-overview {
  @include flex-column;
  height: 100%;

  h2 {
    @include h3-semibold;
    color: $content-default;
    margin-bottom: $gap-content;
  }

  h3 {
    @include text-body-normal;
    color: $content-default;
    font-size: 16px;
    font-weight: 400;
  }

  &__content {
    padding: $padding-secondary;
    flex: 1;
    overflow-y: auto;
  }

  &__info,
  &__audience {
    margin-top: $gap-secondary;
    padding: $padding-tertiary 0px;

    > h4 {
      @include h4-semibold;
      color: $content-default;
      margin-bottom: $gap-primary;
    }
  }

  &__audience {
    > div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: $gap-primary;
    }

    &_age {
      @include flex-row;
      gap: $gap-primary;
      > div {
        flex: 1;
      }
    }

    &_gender {
      @include flex-column;
      gap: $gap-primary;
      > label {
        @include text-body-semibold;
        color: $content-description;
      }
      &_checkboxes {
        @include flex-row;
        gap: $gap-secondary;
      }

      .small-error-msg {
        justify-content: flex-start;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
}
