.btn-config {
  @include flex-row;
  @include center;
  @include label-medium;
  font-weight: 600;
  font-family: $font-family;
  box-sizing: border-box;
  border-radius: $corner-radius-primary;
  font-weight: $font-weight-bold;
  padding: $padding-quinary $padding-quaternary;
}

.btn-primary-solid {
  @extend .btn-config;
  @include label-medium;
  font-weight: 600;
  background: $fill-primary-default;
  color: $content-inverse;

  &:hover:not([disabled]) {
    background: $fill-primary-hover;
  }

  &:active {
    background: $fill-primary-press;
  }

  &[disabled] {
    background: $fill-neutral-disable;
    color: $content-disable;
  }
}

.btn-primary-ghost {
  @extend .btn-config;
  color: $content-primary;
  border: 1px solid $stroke-primary-default;

  &[disabled] {
    color: $content-disable;
  }
}

/*********** Neutral buttons ***********/

.btn-neutral-ghost {
  @extend .btn-config;
  border: 1px solid $stroke-neutral-default;
  color: $content-default;
}

.btn-neutral-solid {
  @extend .btn-config;
  background: $fill-neutral-default;
  color: $content-inverse;
}

/*********** Negative buttons ***********/

.btn-negative-solid {
  @extend .btn-config;
  background: $fill-negative-subtle;
  color: $content-negative;
}

.btn-negative-ghost {
  @extend .btn-config;
  border: 1px solid $stroke-negative-default;
  color: $content-negative;
}

.tabs {
  @include flex-row;
  gap: $gap-quaternary;
  border-bottom: 1px solid $content-subtle;
  > button {
    @include text-body-medium;
    color: $content-description;
    flex: 1;
    padding: $padding-quinary 0;
    margin-bottom: -1px;

    &[class^='--selected'] {
      @include text-body-bold;
      color: $content-default;
      border-bottom: 2px solid $stroke-neutral-press;
    }
  }
}
