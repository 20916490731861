@import '../../../global/styles/config';

.gamer-view-page {
  @include flex-column;
  flex: 1;
  z-index: 0;

  @media only screen and (min-width: $screen-xl) {
    align-items: center;

    > * {
      width: $layout-width;
    }
  }
}
