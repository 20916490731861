@import '../../../global/styles/config';

.ad-surface-list {
  padding: 20px;

  &__heading {
    @include flex-column;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;

    @media only screen and (min-width: $screen-md) {
      @include flex-row;
      align-items: center;
      padding: 0;
    }

    &_title {
      @include flex-row;
      align-items: center;
      gap: 10px;

      > button {
        @include flex-row;
        align-items: center;

        @media (min-width: $screen-md) {
          display: none;
        }
      }

      > h1 {
        font-size: 24px;
        font-weight: $font-weight-bold;

        span {
          margin-left: $gap-primary;
          font-size: 18px;
          font-weight: $font-weight-normal;
          color: $content-default;
        }
      }
    }

    > .button-options {
      @include flex-row;
      justify-content: space-between;
      gap: 10px;

      > button {
        @include flex-row;
        @include text-body-semibold;
        gap: 5px;
        align-items: center;
        gap: $gap-content;
        padding: $padding-secondary;
        height: 40px;

        > svg > path {
          stroke: $primary-500;
        }
      }

      .select {
        width: 100%;
      }

      &.button-options p {
        @include center;
        @include text-body-semibold;
      }
    }
  }

  > span {
    color: $content-positive-intense;
    padding: 5px;
    text-align: center;
  }

  &__main {
    position: relative;
    display: grid;
    gap: 20px;
    padding: 20px 0;

    > .loading_fallback_container {
      height: 100px;
      background-color: transparent;
      align-items: unset;
    }
  }
}
