@import '../../global/styles/config';
.statistics-container {
  @include flex-row;
  > hgroup {
    flex: 1;
    @include flex-column;
    align-items: center;

    @media (min-width: $screen-md) {
      width: 100px;
    }

    > h2 {
      @include text-caption-normal;

      color: $content-description;

      @media (min-width: $screen-xl) {
        @include text-body-small-normal;
      }
    }

    > h3 {
      @include text-body-small-semibold;

      @media (min-width: $screen-xl) {
        @include text-body-semibold;
      }
    }
  }
}
