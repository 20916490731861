@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin center {
  align-items: center;
  justify-content: center;
}

@mixin custom-scroll {
  &::-webkit-scrollbar {
    width: 22px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(151, 149, 153, 0.5);
    border-radius: 22px;
    border: 7px solid transparent;
    background-clip: padding-box;
  }
}

@mixin text-clamp($numberOfLines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $numberOfLines;
  line-clamp: $numberOfLines;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

@mixin drop-down($top, $width) {
  display: none;
  position: absolute;
  background-color: $fill-bg-card;
  border-radius: $corner-radius-primary;
  top: $top;
  width: $width;
  z-index: 1;
  overflow: hidden;
}

@mixin drop-down-links {
  > a,
  > div,
  > button {
    color: $content-default;
    display: block;
    padding: 15px;

    * {
      color: $content-default;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

@mixin dots {
  &:not(:last-of-type) {
    &::after {
      content: '';
      display: inline-block;
      position: relative;
      top: 0px;
      right: -10px;
      width: 5px;
      height: 5px;
      background: $content-description;
      border-radius: 50%;
    }
  }
}

@mixin dots-one {
  &::after {
    content: '';
    display: inline-block;
    position: relative;
    top: 0px;
    right: -10px;
    width: 5px;
    height: 5px;
    background: $content-description;
    border-radius: 50%;
  }
}

@mixin filter-buttons {
  @include flex-row;
  @include center;
  background: $fill-neutral-subtle;
  border-radius: $corner-radius-round;
  padding: $padding-content;

  > button {
    @include text-body-small-medium;
    color: $content-default;
    height: 36px;
    padding: 0 $padding-primary;
  }

  .active {
    @include text-body-small-semibold;
    background: $fill-bg-card;
    border-radius: $corner-radius-round;
  }
}
