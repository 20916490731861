@import '../../../global/styles/config';

.organization {
  position: relative;
  cursor: pointer;
  z-index: 2;

  padding: $padding-tertiary 0 0 0;
  border-top: 1px solid $stroke-neutral-subtle;
  margin-top: $padding-tertiary;

  > a {
    @include flex-row;
    align-items: center;
    gap: 10px;

    img {
      border-radius: $corner-radius-primary;
      overflow: hidden;
      width: 32px;
      height: 32px;
      > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    > div {
      p {
        @include text-body-small-medium;
        color: $content-default;

        &:first-of-type {
          @include text-caption-normal;
          color: $content-description;
        }
      }
    }
  }
}
