@import '../../global/styles/config';

.user {
  @include flex-row;
  @include text-body-normal;
  width: 100%;
  align-items: center;
  gap: 10px;
  height: 55px;
  padding: 0 10px;

  &:first-child:hover {
    border-top-right-radius: $corner-radius-primary;
    border-top-left-radius: $corner-radius-primary;
  }
  &:last-child:hover {
    border-bottom-right-radius: $corner-radius-primary;
    border-bottom-left-radius: $corner-radius-primary;
  }

  > img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }

  > p {
    @include text-clamp(2);
    @include text-body-semibold;
    color: $content-default;
    text-align: left;
    word-break: break-all;
  }
}

.select {
  cursor: pointer;

  &:hover {
    background-color: $fill-neutral-subtle;
  }
}
