@import '../../../../../global/styles/config/';

div.game-details-step {
  @include flex-column;
  gap: $gap-secondary;

  &__checkboxes {
    @include flex-column;

    &__os {
      display: grid;
      grid-template-columns: 1fr 3fr 2fr;
      gap: $gap-secondary;

      .checkbox-container {
        height: 40px;
      }
    }
  }

  &__mau-distribution {
    &__item {
      display: grid;
      grid-template-columns: 0.1fr 10fr 5fr;
      gap: $gap-primary;

      &__index {
        height: 40px;
        @include flex-column;
        justify-content: flex-end;
      }
    }
  }
}
