@import '../../global/styles/config';

.aside-popup-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  transform: translateX(-130%);
  z-index: 3;

  @media (min-width: $screen-md) {
    position: relative;
    width: unset;
    height: unset;
    padding: unset;
    transform: translateX(0);
    @include flex-row;
  }

  &--open {
    transform: translateX(0);
  }

  &__backdrop {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.85);
    @media (min-width: $screen-md) {
      display: none;
    }
  }

  &__menu {
    position: relative;
    background: $fill-bg-screen;
    width: 80%;
    height: 100%;
    padding: 25px 10px;

    @media (min-width: $screen-md) {
      width: unset;
      height: unset;
      padding: unset;
      background: unset;
      @include flex-row;
    }

    &_close {
      position: absolute;
      right: 10px;

      @media (min-width: $screen-md) {
        display: none;
      }
    }
  }

  .aside-menu__desktop {
    display: flex;
    flex-direction: column;
    flex: 1;

    > button {
      padding: 5px 10px;
      justify-content: flex-start;
    }
  }

  .aside-menu__mobile {
    display: none;
  }
}
