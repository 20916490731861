@import '../../global/styles/config';

.header-container {
  width: 100%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
  background: $fill-bg-card;
  margin-bottom: 25px;
}

.header {
  display: grid;
  grid-template-columns: auto 1fr 150px;
  align-items: center;
  width: 100%;
  position: relative;
  height: 55px;
  padding: 0 15px;
  margin: 0 auto;

  @media (min-width: $screen-md) {
    gap: 20px;
  }

  @media screen and (min-width: $screen-xl) {
    height: 65px;
    max-width: $layout-width;
    padding: 0;
  }

  &__gamer-earnings {
    position: absolute;
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    margin: 0 16px;
    gap: 8px;

    @media (max-width: $screen-md) {
      display: none;
    }

    right: 100px;

    > div {
      @include flex-row;
      align-items: center;
      gap: 8px;
      > p {
        @include h4-semibold;
        color: $content-description;
      }
    }
  }

  &__hamburger_menu {
    @media (min-width: $screen-md) {
      display: none;
    }
    > button {
      @include flex-row;
      width: 40px;
      height: 100%;
    }

    .aside-popup-menu {
      @media (min-width: $screen-md) {
        display: none;
      }

      &__menu {
        z-index: 3;
        > div {
          @include flex-column;
          gap: 30px;
          padding: 0 20px;

          > a {
            @include h4-bold;
            color: $content-default;
          }

          .page-selected {
            &::after {
              top: 9px;
            }
          }
          > div {
            > p {
              @include text-body-semibold;

              svg {
                display: none;
              }
            }
            > div {
              @include flex-column;
              gap: 10px;
              margin-left: 20px;
              padding: 10px 0;

              > a {
                @include text-body-semibold;

                &::after {
                  content: none;
                }
              }
            }
          }
        }
      }
    }
  }

  &__rene_logo {
    @include flex-row;
    height: 100%;
    align-items: center;
    cursor: pointer;

    picture {
      @include flex-row;
      align-items: center;
    }

    img {
      width: 150px;
    }
  }

  &__navigation {
    display: none;
    gap: 20px;
    justify-content: flex-start;
    position: relative;

    @media (min-width: $screen-md) {
      @include flex-row;
    }

    @media (min-width: $screen-lg) {
      gap: 40px;
      margin-left: 80px;
    }

    > a,
    > p {
      @include text-body-normal;
      color: $content-description;

      &.page-selected {
        @include text-body-bold;
        position: relative;
        color: $content-primary;
      }
    }
  }

  &__user_actions {
    @include flex-row;
    height: 100%;
    align-items: center;
    justify-self: flex-end;
    &_info {
      position: relative;
      &_image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: $corner-radius-primary;
        overflow: hidden;
        cursor: pointer;

        > img {
          height: 100%;
          aspect-ratio: 1;
          object-fit: cover;
        }
      }

      &_profile_dropdown {
        @include drop-down(35px, 240px);
        z-index: 3;
        right: 0;
        width: 250px;
        padding: 15px;
        box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.1), 0px 20px 32px -24px rgba(0, 0, 0, 0.1);

        > div:hover {
          cursor: default;
        }

        > a:not(.header__user_actions_info_profile_dropdown_view) {
          @include text-body-small-medium;
          display: block;
          color: $content-default;
          padding: 10px 0;
        }

        &_user {
          > p {
            @include text-caption-normal;
            color: $content-default;
            word-break: break-all;

            &:first-of-type {
              @include text-body-small-medium;
            }
          }
        }

        &_view {
          @include flex-row;
          @include center;
          gap: 10px;
          border: 1px solid $content-primary;
          border-radius: $corner-radius-primary;
          margin-top: 5px;

          > p {
            @include label-x-small;
            color: $content-primary;
          }

          > svg {
            path {
              fill: $content-primary;
            }
          }
        }

        &_account {
          border-top: 1px solid $stroke-neutral-subtle;
          margin-top: $padding-tertiary;
        }

        &_logout {
          width: 100%;
          height: 100%;
          text-align: left;
          @include text-body-small-medium;
          color: $content-negative;
        }
      }

      &:hover &_profile_dropdown {
        display: block;
      }
    }
  }
}
