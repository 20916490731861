@import '../../../global/styles/config';

.create-password-page {
  @include flex-column;
  align-items: center;
  flex: 1;
  padding: 24px;

  &__logo {
    align-self: center;
    padding: 16px 0 40px;
    z-index: 1;

    > img {
      width: 150px;

      @media (min-width: $screen-lg) {
        width: 350px;
      }
    }
  }
}

.create-password,
.set-password-success {
  @include flex-column;
  flex: 1;
  gap: 10px;
  padding: 15px;
  width: 100%;
  border-radius: $corner-radius-tertiary;
  border: 1px solid $stroke-bg-card;
  background: $fill-bg-card;

  /* shadow-bottom-300 */
  box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.1), 0px 20px 32px -24px rgba(0, 0, 0, 0.1);
  padding: 16px;
  z-index: 1;

  @media only screen and (min-width: $screen-md) {
    width: 460px;
    flex: unset;
    min-height: 500px;
  }

  > h1 {
    @include h4-semibold;
    text-align: center;
    margin-bottom: 20px;
  }

  > button,
  a {
    @include text-body-semibold;
    margin-top: 10px;
    align-self: center;
    width: 200px;
    height: 40px;
  }

  > button {
    height: 56px;
  }
}

.set-password-success {
  > div {
    margin: 0 auto;
    @include flex-row;
    @include center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: $fill-positive-subtle-hover;
    svg {
      path {
        stroke: $content-default;
      }
    }
  }
}

.create-password {
  > h2 {
    @include text-body-semibold;
    text-align: center;
  }

  > h3 {
    @include text-body-small-normal;
    color: $content-negative;
  }

  &__email {
    @include text-body-small-normal;
    color: $content-negative;
    text-align: center;

    @media (min-width: $screen-lg) {
      text-align: left;
    }
  }

  &__resend-otp {
    @include text-body-small-normal;
    text-align: center;

    @media (min-width: $screen-lg) {
      text-align: left;
      @include flex-row;
      gap: 10px;
      align-items: center;
    }

    > button {
      @include text-body-small-semibold;
      margin-top: 10px;
      @media (min-width: $screen-lg) {
        margin-top: 0;
      }
    }
  }

  &__back {
    @include text-caption-normal;
    margin-bottom: 25px;
    text-align: center;

    @media (min-width: $screen-lg) {
      text-align: left;
    }

    > button {
      @include text-caption-normal;
      color: $content-negative;
      margin-left: 5px;
    }
  }
}
