@import '../../global/styles/config/';

.campaigns-list {
  @include flex-column;
  gap: 24px;

  h3 {
    @include text-body-semibold;
    color: $content-default;
  }
}
