@import '../../../global/styles/config';

.new-organization-modal {
  width: 100%;
  padding: 15px;
  border-radius: $corner-radius-primary;
  overflow: auto;

  label {
    margin-bottom: 5px;
  }

  &__heading {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    > h2 {
      @include h4-semibold;
    }
    > button {
      display: flex;
    }
  }

  .select-container {
    margin-top: 15px;
    .select {
      &__options {
        > button {
          height: unset;
        }
      }
      &__org-type {
        padding: 8px;

        > p {
          &:last-of-type {
            @include text-caption-normal;
            color: $content-description;
          }
        }
      }
    }
  }

  &__terms {
    width: 100%;
    > div {
      @include flex-row;
      align-items: center;
      gap: 10px;
      width: 100%;
      > p {
        @include text-caption-normal;

        > a {
          margin-left: 5px;
          color: $content-primary;
        }
      }
    }

    > p {
      @include text-caption-normal;
      color: $content-negative;
      text-align: right;
    }
  }

  &__actions {
    @include flex-row;
    @include center;
    gap: 10px;
    padding-top: 30px;
    > button {
      width: 150px;
      height: 40px;
    }

    @media screen and (min-width: $screen-md) {
      justify-content: flex-end;
    }
  }

  @media screen and (min-width: $screen-md) {
    width: 500px;
  }
}
