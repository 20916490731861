@import '../../../global/styles/config';

.points-and-rewards {
  @include flex-column;
  flex: 1;

  &__heading {
    @include flex-row;
    align-items: center;
    gap: 10px;
    > h1 {
      @include h4-semibold;
    }
    > button {
      @include flex-row;
      align-items: center;
      height: 40px;

      @media (min-width: $screen-md) {
        display: none;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__content {
    margin-top: 20px;

    > h2 {
      @include text-body-semibold;
      margin: 20px 0;
    }

    &_campaigns {
      @include flex-column;
      gap: 16px;

      .loading_fallback_container {
        position: relative;
        height: 250px;
        background-color: transparent;
      }
    }
  }
}

.public-campaign-bar {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  border-radius: $corner-radius-primary;
  border: 1px solid $stroke-bg-card;
  background: $fill-bg-card;
  padding: 16px;

  @media (min-width: $screen-xl) {
    grid-template-columns: 80px 1fr 2fr;
  }

  &__info {
    @include flex-row;
    gap: 10px;

    @media (min-width: $screen-xl) {
      grid-column: 1 / 3;
    }

    &_showGoals {
      > svg {
        transform: rotate(180deg);
      }
    }
    > img {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: $corner-radius-primary;

      @media (min-width: $screen-lg) {
        grid-column: 1 / 2;
      }
    }

    > div {
      @include flex-column;
      justify-content: center;
      height: 100%;

      @media (min-width: $screen-lg) {
        grid-column: 2 / 3;
      }

      > h2 {
        @include text-body-semibold;
        min-width: 100px;
      }

      > button {
        @include flex-row;
        @include label-x-small;
        > p {
          color: $content-primary;
        }

        > svg {
          width: 16px;
          height: 16px;

          path {
            stroke: $content-primary;
          }
        }
      }
    }
  }

  &__goals {
    @include flex-column;
    gap: 16px;

    background-color: $fill-neutral-subtle;
    padding: 8px;
    margin-top: 16px;

    @media (min-width: $screen-lg) {
      grid-column: 1 / 5;
      grid-row: 2 / 3;
    }

    &_fulfilled {
      > svg {
        path {
          fill: $content-primary;
          stroke: $content-primary;
        }
      }

      > p {
        color: $content-primary;
      }

      > div {
        > p {
          &:nth-child(2) {
            color: $positive-500;
            @include text-body-semibold;
            svg {
              path {
                stroke: $positive-500;
              }
            }
          }
        }
      }
    }

    > div {
      display: grid;
      grid-template-columns: 24px 6fr 3fr;
      align-items: center;
      gap: 10px;

      @media (min-width: $screen-md) {
        grid-template-columns: 24px 3fr 1fr;
      }

      > p {
        @include text-body-medium;
      }

      > a {
        @include text-body-medium;
        cursor: pointer;
        svg {
          position: relative;
          top: 2px;
          path {
            fill: $content-primary;
            stroke: $content-primary;
          }
        }
      }

      > div {
        @include flex-column;
        gap: 8px;

        @media (min-width: $screen-md) {
          flex-direction: row;
          justify-content: flex-end;
        }
        > p {
          @include flex-row;
          font-weight: $font-weight-bold;
          align-items: center;
          justify-content: flex-end;
          gap: 5px;

          > img {
            width: 16px;
            height: 16px;
          }
        }

        > a {
          @include label-x-small;
          text-align: center;
          border: 1px solid $content-default;
          padding: 6px;
          color: $content-default;
          min-width: 90px;
        }
      }
    }
  }

  &__stats {
    @include flex-row;
    @include center;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 16px;
    gap: 16px;

    @media (min-width: $screen-md) {
      flex-wrap: nowrap;
    }

    @media (min-width: $screen-xl) {
      grid-column: 3 / 4;
      padding-top: 0;
    }

    > div,
    button {
      @include flex-column;
      align-items: center;
      gap: 5px;
      width: 150px;

      @media (min-width: $screen-md) {
        width: 120px;
      }

      @media (min-width: $screen-xl) {
        flex: 1;
      }

      > h2 {
        @include text-body-semibold;
      }

      > h3 {
        @include text-body-semibold;
        @include flex-row;
        align-items: center;
      }
    }

    > div:first-of-type {
      > h2 > span {
        display: none;

        @media (min-width: $screen-md) {
          display: inline;
        }
      }
    }

    &_multiplier {
      > h3 {
        color: $content-primary;
      }
    }
  }
}
