@import '../../global/styles/config';

.search-container {
  width: 100%;
  .search-container__inner {
    .search-container__inner_search {
      @include flex-row;
      @include center;
      height: 48px;
      border-radius: var(--corner-radius-primary, 8px);
      border: 1px solid var(--stroke-neutral-subtle, #dcdce0);
      padding: 5px 10px;

      svg {
        width: 24px;
        height: 24px;
        path {
          stroke: $content-default;
        }
      }

      input {
        height: 100%;
        width: 100%;
        background: none;
        border: none;
        color: $content-default;
        &::placeholder {
          @include text-body-normal;
          color: $content-subtle;
        }

        &:focus {
          outline: none;
        }
        &:disabled {
          color: $content-disable;
        }
      }
      &.disabled {
        background: $fill-neutral-disable;
      }
    }
  }
}
