@import '../../global/styles/config';

.notifications_container {
  position: relative;
  margin: 0 15px;
  display: flex;
  cursor: pointer;

  &__icon_bell {
    @include flex-row;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: var(--fill-neutral-subtle, rgba(0, 0, 0, 0.05));
  }

  &__icon_counter {
    @include flex-row;
    justify-content: center;
    position: absolute;
    width: 15px;
    height: 15px;
    top: 0;
    right: 0;
    background: $content-warning-intense;
    border-radius: 50%;
    > p {
      @include text-caption-bold;
      color: $content-description;
    }
  }

  &__dropdown {
    display: none;
    position: absolute;
    width: 340px;
    top: 35px;
    right: -50px;
    background-color: $fill-bg-card;
    z-index: 1;

    @media screen and (min-width: $screen-md) {
      top: 25px;
    }

    > h2 {
      @include text-body-semibold;
      padding: 10px 15px 15px;
      border-bottom: 1px solid $content-description;
      margin: 0 10px;
    }

    .game_authorization_notifications {
      @include flex-column;
      @include center;
      padding: 10px;
      margin: 10px;
      gap: 10px;

      button {
        @include text-body-small-semibold;
      }

      &:hover {
        background-color: $content-description;
        &:first-of-type {
          border-top-left-radius: $corner-radius-primary;
          border-top-right-radius: $corner-radius-primary;
        }

        &:last-of-type {
          border-bottom-left-radius: $corner-radius-primary;
          border-bottom-right-radius: $corner-radius-primary;
        }
      }
    }
  }

  &:hover .notifications_container__dropdown {
    display: block;
  }
}
