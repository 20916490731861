@import '../../global/styles/config';

.iab-select {
  width: 100%;
  position: relative;

  &__label {
    @include text-body-semibold;
    color: $content-description;
  }

  &__trigger {
    @include flex-row;
    align-items: center;
    justify-content: space-between;
    padding: $padding-quinary $padding-primary;
    border-bottom: 1px solid $stroke-neutral-subtle;
    cursor: pointer;

    &_selected {
      @include text-clamp(1);
      @include text-body-medium;
      color: $content-default;

      span {
        @include text-body-normal;
        color: $content-disable;
      }
    }

    &_arrow {
      border: solid $content-default;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
      margin-left: 10px;
      margin-top: 5px;
      transition: transform 0.2s ease;

      .open & {
        transform: rotate(-135deg);
      }
    }
  }

  &__checkbox {
    width: 16px;
    height: 16px;
    border: 1px solid $stroke-neutral-default;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $corner-radius-secondary;
    padding: $padding-content-small;

    &--selected {
      background-color: $fill-primary-default;
      border-color: $fill-primary-default;
    }
  }

  &__options {
    @include flex-column;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    height: 450px;
    z-index: 10;
    background: $fill-bg-screen;
    border-radius: $corner-radius-primary;
    overflow: hidden;

    box-shadow: 0px 0px 36px 0px $display-neutral-light, 0px 20px 32px -24px $display-neutral-light;

    &_search {
      margin: $gap-content;
      .search-container__inner_search {
        height: 48px;
      }
    }

    .lds-ring {
      position: relative;
      inset: 0;
      margin: auto;
    }

    &_list {
      flex: 1;
      overflow-y: auto;
      &_item {
        @include flex-row;
        align-items: center;
        padding: $padding-tertiary $padding-quinary;

        span {
          @include flex-row;
          @include text-body-small-semibold;
          color: $content-description;
        }

        > button {
          @include flex-row;
          align-items: center;
          gap: $gap-content;
        }

        &_arrow {
          border: solid $content-default;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 3px;
          transform: rotate(-45deg);
          margin-left: 5px;
          margin-top: 4px;
          transition: transform 0.2s ease;

          .open & {
            transform: rotate(45deg);
          }
        }

        &_expand {
          position: absolute;
          padding: 10px 30px;
          width: max-content;
          right: 0;

          > div {
            @include flex-row;
            align-items: center;
            gap: $gap-content;
          }

          > button {
            @include label-x-small;
            color: $content-primary;
            margin-left: auto;
          }
        }
      }
    }

    &_footer {
      @include flex-row;
      align-items: center;
      justify-content: space-between;
      padding: $padding-quinary $padding-primary;
      height: 48px;
      border-top: 1px solid $stroke-neutral-subtle;
      background: $fill-bg-card;

      > div {
        @include flex-row;
        align-items: center;
        gap: $gap-content;

        > button {
          @include flex-row;
          align-items: center;
          gap: $gap-content;

          svg {
            path {
              stroke: $content-default;
            }
          }
        }

        p {
          @include text-body-normal;
          color: $content-default;
          margin-left: $gap-primary;
        }
      }

      > button {
        padding: $padding-content $padding-quinary;
      }
    }
  }

  &__options_list_nested {
    margin-left: 20px;
  }

  &__options_list_item_arrow {
    transition: transform 0.2s ease;

    &.expanded {
      transform: rotate(45deg);
    }
  }
}
