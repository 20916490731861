@import '../../../global/styles/config';

.gamer-dashboard-page {
  @include flex-column;
  gap: 8px;
  position: relative;

  &__offers {
    position: relative;
    @include flex-row;
    align-items: center;
    text-align: left;
    border-radius: 8px;
    padding: 8px;
    gap: 16px;
    margin: 24px 16px 0;
    background: $content-primary;

    @media (min-width: $screen-lg) {
      margin-bottom: 24px;
      padding: 16px 24px;
    }

    &_envelop {
      @include flex-row;
      align-items: center;
      width: max-content;
      position: relative;

      svg {
        path {
          fill: $fixed-inverse;
        }
      }

      &_counter {
        @include flex-row;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 15px;
        height: 15px;
        top: -5px;
        right: -5px;
        background: $negative-500;
        border-radius: 50%;

        > p {
          @include text-body-semibold;
          color: $fixed-inverse;
        }
      }
    }

    &_text {
      > p {
        @include text-body-medium;
        @include text-clamp(1);

        &:first-of-type {
          font-weight: $font-weight-bold;
          color: $fixed-inverse;
        }

        &:last-of-type {
          color: $fixed-inverse;
        }
      }
    }

    &_tooltip {
      position: absolute;
      @include flex-column;
      background-color: $fill-bg-card;
      border-radius: $corner-radius-primary;
      padding: 12px;
      gap: 8px;
      top: calc(100% + 16px);
      left: 0;
      z-index: 4;

      &::after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 20px;
        border-width: 8px;
        border-style: solid;
        border-color: transparent transparent $fill-bg-card transparent;
      }

      &_heading {
        @include text-body-semibold;
      }

      &_text {
        @include text-body-small-normal;
        color: $content-description;
      }

      &_close-button {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;

        transition: transform 0.1s;

        &:hover {
          transform: scale(1.1);
        }

        &:active {
          transform: scale(0.9);
        }
      }
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3;
  }

  &__earnings {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    margin: 0 16px;
    gap: 8px;

    @media (min-width: $screen-lg) {
      display: none;
    }

    > p {
      @include h4-semibold;
    }

    > div {
      @include flex-row;
      align-items: center;
      gap: 8px;

      > p {
        @include h4-semibold;
      }

      svg {
        path {
          stroke: $content-default;
        }
      }
    }
  }

  &__games {
    @include flex-column;
    gap: 16px;
    padding: 24px 16px;

    @media (min-width: $screen-lg) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    > h2 {
      @include h4-semibold;

      @media (min-width: $screen-lg) {
        grid-column: 1 / span 2;
      }
    }

    .loading_fallback_container {
      position: relative;
      background: transparent;
      height: 200px;

      @media (min-width: $screen-lg) {
        grid-column: 1 / span 2;
      }
    }

    &_game {
      @include flex-row;
      align-items: center;
      padding: 16px;
      gap: 24px;
      border-radius: $corner-radius-primary;
      border: 1px solid $stroke-bg-card;
      background: $fill-bg-card;

      > img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 8px;
      }

      > div {
        @include flex-column;
        justify-content: center;
        gap: 10px;

        > h3 {
          @include text-body-semibold;
          text-align: left;
        }

        > div {
          @include flex-row;
          gap: 24px;

          &:has(.connected) {
            > p:first-of-type {
              background-color: $fill-primary-subtle;
            }
          }

          p {
            @include dots;
            @include text-caption-medium;

            &:first-of-type {
              padding-left: 4px;
              border-radius: 4px;
              background-color: $fill-primary-subtle;
            }

            &:last-of-type {
              margin-left: -4px;
            }
          }
        }
      }
    }
  }
}

.cursor-default {
  cursor: default;
}
