@import '../../../global/styles/config';

.branded-objects-list {
  padding: 20px;

  &__main {
    @include flex-column;
    gap: $gap-content;

    display: flex;
    flex-direction: column;
    gap: 10px;

    &__content {
      @include flex-column;
      gap: $gap-primary;
      padding: 0px calc($padding-primary * 4);
      > * {
        color: $gray-900;
        font-size: 14px;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    > h1 {
      font-size: 24px;
      font-weight: $font-weight-bold;

      span {
        margin-left: $gap-primary;
        font-size: 18px;
        font-weight: $font-weight-normal;
        color: $content-default;
      }
    }

    button {
      @include flex-row;
      gap: $gap-content;
      padding: $padding-secondary;
      height: 40px;

      > svg > path {
        stroke: $primary-500;
      }
    }
  }
}
