@import '../../../../../global/styles/config';

.review {
  @include flex-column;
  height: 100%;

  h2 {
    @include h3-semibold;
    color: $content-default;
    margin-bottom: $gap-content;
  }

  h3 {
    @include text-body-normal;
    color: $content-default;
    font-size: 16px;
    font-weight: 400;
  }

  &__content {
    padding: $padding-secondary;
    flex: 1;
    overflow-y: auto;
  }

  &__sections {
    @include flex-column;
    margin: $gap-secondary 0;
    gap: $gap-secondary;
  }

  &__overview,
  &__ads-placement,
  &__budget {
    margin-bottom: $gap-content;
    border-radius: $corner-radius-primary;
    border: 1px solid $stroke-bg-card;
    background: $fill-bg-card;
    padding: $padding-secondary;

    &__header {
      @include flex-row;
      justify-content: space-between;
      align-items: center;

      > div {
        @include flex-row;
        align-items: center;
        gap: $gap-content-small;

        > h4 {
          @include h4-semibold;
          color: $content-default;
        }

        svg {
          path {
            stroke: $content-default;
          }
        }
      }

      > button > svg {
        width: 24px;
        height: 24px;
        path {
          stroke: $content-default;
        }
      }
    }
  }

  &__overview_data {
    display: grid;
    grid-template-columns: 120px 1fr;
    gap: $gap-tertiary 0;
    margin-top: $gap-primary;

    p {
      @include text-body-small-normal;
      color: $content-default;

      &:nth-child(2n) {
        @include text-body-small-medium;
      }
    }
  }

  &__ads-placement_data {
    &_settings {
      display: grid;
      grid-template-columns: 120px 1fr;
      gap: $gap-tertiary 0;
      margin-top: $gap-primary;

      h5 {
        @include text-body-semibold;
        color: $content-default;
        grid-column: 1 / span 2;
      }

      p {
        @include text-body-small-normal;
        color: $content-default;

        &:nth-of-type(2n) {
          @include text-body-small-medium;
        }
      }
    }

    &_ads {
      margin-top: $gap-primary;

      h5 {
        @include text-body-semibold;
        color: $content-default;
        grid-column: 1 / span 2;
        margin-bottom: $gap-tertiary;
      }

      &_ad {
        @include flex-row;
        gap: $gap-primary;
        border-radius: $corner-radius-primary;
        border: 1px solid $stroke-bg-screen;
        background: $fill-bg-screen;
        margin-bottom: $gap-primary;

        > img {
          width: 200px;
          height: 200px;
          object-fit: cover;
        }

        > div {
          @include flex-column;
          justify-content: center;
          gap: $gap-tertiary;

          > h6 {
            @include text-body-semibold;
            color: $content-default;
            grid-column: 1 / span 2;
          }
          p,
          span {
            @include text-body-small-normal;
            color: $content-default;
          }

          span {
            @include text-body-small-medium;
          }

          > div {
            @include flex-row;
            align-items: center;
            gap: 16px;
            button {
              height: 32px;
              padding: 0 $padding-quinary;
            }
          }
        }
      }
    }
  }

  &__budget_data {
    h5 {
      margin-top: $gap-primary;
      @include text-body-semibold;
      color: $content-default;
      margin-bottom: $gap-tertiary;
    }

    p {
      @include text-body-small-normal;
      color: $content-default;

      span {
        @include text-body-small-medium;
      }
    }

    > div {
      table {
        width: 100%;
        border-collapse: collapse;

        th,
        td {
          padding: $padding-tertiary;
          text-align: left;
          border-bottom: 1px solid $stroke-neutral-subtle;
        }

        th {
          @include text-body-small-bold;
          color: $content-description;
        }

        td {
          @include text-body-small-normal;
          color: $content-default;
        }
      }

      p {
        @include text-body-medium;
        text-align: right;
        color: $content-default;
        margin-top: $gap-tertiary;

        span {
          @include text-body-bold;
        }
      }
    }
  }
}
