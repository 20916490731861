@import '../../../global/styles/config';

.organization-campaign-page {
  .catalog-popup {
    top: 0;

    @media (min-width: $screen-lg) {
      width: 800px;
    }
  }

  &__back {
    padding: 10px;
    margin: 0 -15px;

    @media (min-width: $screen-md) {
      background-color: transparent;
      margin: 0;
      padding: 0;
      margin-bottom: 10px;
    }

    svg {
      path {
        stroke: $content-default;
      }
    }

    > a {
      @include flex-row;
      align-items: center;

      p {
        @include text-body-semibold;
      }
    }

    &_desktop {
      display: none;
      @media (min-width: $screen-md) {
        display: block;
      }
    }

    &_mobile {
      @media (min-width: $screen-md) {
        display: none;
      }
    }
  }

  &__main {
    .loading_fallback_container {
      background: transparent;
      position: relative;
      height: 200px;
      justify-items: center;

      > img {
        grid-column: 1 / span 3;
      }
    }

    &_graph {
      margin: 16px 0;
      padding: 16px;
    }

    &_graph {
      margin: 0 -15px;
      border-radius: $corner-radius-primary;
      border: 1px solid $stroke-bg-card;
      background: $fill-bg-card;

      @media (min-width: $screen-md) {
        margin: 0;
        margin-bottom: 24px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      &_heading {
        @include flex-column;
        gap: 10px;

        @media (min-width: $screen-md) {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        > h2 {
          @include text-body-semibold;
        }

        .select-container {
          @media (min-width: $screen-md) {
            width: 240px;
          }
          .select {
            .select__options {
              button:nth-child(4) {
                border-bottom: 2px solid $content-default;
              }
            }
          }
        }
      }

      &_legend {
        @include flex-row;
        margin-top: 10px;
        gap: 30px;
        @media (min-width: $screen-md) {
          justify-content: center;
          gap: 40px;
        }

        > p {
          @include flex-row;
          align-items: center;
          gap: 10px;

          &::before {
            content: '';
            width: 16px;
            height: 16px;
            background-color: $content-primary;

            &:first-child {
              background-color: $stroke-positive-default;
            }
          }

          &:first-child {
            &::before {
              background-color: $stroke-positive-default;
            }
          }
        }
      }
    }
    &_ads {
      margin-top: 24px;

      border-radius: $corner-radius-primary;
      border: 1px solid $stroke-bg-card;
      background: $fill-bg-card;
      padding: 16px;

      @media (min-width: $screen-xl) {
        margin-top: 0;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      > div {
        &:first-child {
          @include flex-row;
          align-items: center;
          justify-content: space-between;

          > h1 {
            @include h4-semibold;

            span {
              margin-left: 5px;
            }
          }

          > button {
            @include text-body-semibold;
            width: 110px;
            height: 40px;
            border-radius: 8px;
            border: 1px solid $content-primary;
          }
        }

        &:last-child {
          display: grid;
          margin-top: 25px;
          gap: 10px;
          position: relative;

          grid-template-columns: 1fr;

          @media (min-width: $screen-md) {
            grid-template-columns: 1fr 1fr;
          }

          @media (min-width: $screen-lg) {
            grid-template-columns: 1fr 1fr 1fr;
          }

          > button {
            all: unset;
            @include flex-row;
            align-items: center;
            height: 115px;
            gap: 20px;
            padding: 0 16px;
            border-radius: $corner-radius-primary;
            border: 1px solid $stroke-bg-card;
            background-color: $fill-bg-card;
            position: relative;

            > div {
              &:first-of-type {
                width: 80px;
                min-width: 80px;
                height: 80px;
                img,
                video {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: $corner-radius-primary;
                }
              }

              &:last-of-type {
                @include flex-column;
                gap: 5px;
                > p {
                  @include text-body-semibold;
                  @include text-clamp(1);

                  &:first-of-type {
                    @include text-caption-normal;
                    position: relative;
                    top: 5px;
                  }
                }
                > div {
                  @include flex-row;
                  gap: 16px;
                  > p {
                    @include text-caption-normal;
                    color: $content-description;

                    span {
                      font-weight: $font-weight-bold;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &_empty {
      @include flex-column;
      @include center;
      height: 200px;
      margin: 0 auto;
      grid-column: 1 / span 3;

      svg {
        width: 50px;
        height: 50px;

        @media (min-width: $screen-md) {
          width: 70px;
          height: 70px;
        }
      }

      p {
        @include text-body-semibold;
        color: $content-default;
      }
    }
  }
}

.custom-date-modal {
  @media (min-width: $screen-md) {
    max-width: unset !important;
    width: 600px;
  }

  &__heading {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;

    > h1 {
      @include h4-semibold;
    }
  }

  &__dates {
    @include flex-column;
    gap: 20px;
    margin-top: 30px;

    @media (min-width: $screen-md) {
      flex-direction: row;
    }

    .select_container {
      flex: 1;
    }
  }

  &__error {
    @include error-msg;
  }

  &__actions {
    @include flex-row;
    @include center;
    gap: 10px;
    padding-top: 25px;
    > button {
      @include text-body-semibold;
      width: 150px;
      height: 40px;
    }

    @media (min-width: $screen-xl) {
      justify-content: flex-end;
    }
  }
}
