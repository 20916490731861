@import '../../global/styles/config';

.multi-select {
  position: relative;
  width: 100%;
  font-size: 14px;

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &__label {
    @include text-body-semibold;
    display: block;
    color: $content-description;
    margin-bottom: $gap-content;
  }

  &__trigger {
    padding: 8px 12px;
    border-bottom: 1px solid $stroke-neutral-subtle;
    border-radius: 4px;
    background: #fff;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 38px;

    &.open {
      border-radius: $corner-radius-secondary;
      border-bottom: 2px solid $stroke-neutral-selected;
      background: $fill-neutral-subtle;
    }
  }

  &__selected {
    @include text-body-medium;
    @include text-clamp(1);
    color: $content-default;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;

    > span {
      @include text-body-normal;
      color: $content-subtle;
    }
  }

  &__arrow {
    border: solid $content-default;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    margin-left: 10px;
    margin-top: 5px;
    transition: transform 0.2s ease;

    .open & {
      transform: rotate(-135deg);
    }
  }

  &__options {
    position: absolute;
    left: 0;
    right: 0;
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
    border-radius: $corner-radius-primary;
    background: $fill-bg-screen;
    padding: $padding-content;

    box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.1), 0px 20px 32px -24px rgba(0, 0, 0, 0.1);
  }

  &__option {
    width: 100%;
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $fill-neutral-subtle;
    }
  }

  &__checkbox {
    width: 16px;
    height: 16px;
    border: 1px solid $stroke-neutral-default;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $corner-radius-secondary;
    padding: $padding-content-small;

    .selected & {
      background-color: $fill-primary-default;
      border-color: $fill-primary-default;
    }
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
}
