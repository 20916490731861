@import '../../../global/styles/config';

.organization-brands-page {
  &__header {
    margin-top: 20px;
    display: grid;
    row-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;

    @media (min-width: $screen-lg) {
      grid-template-columns: 1fr 140px;
      grid-template-rows: 1fr 1fr;
    }

    > button {
      @include label-medium;
      width: 100%;
      grid-column: 1 / span 3;
      justify-self: flex-end;
      height: 48px;

      > p {
        color: $fixed-inverse;
      }
      @media (min-width: $screen-lg) {
        grid-column: 2 / 3;
        grid-row: 2;
      }
    }

    > h2 {
      @include h2-semibold;
    }

    .search-container {
      grid-column: 1 / span 3;

      .search-container__inner {
        width: 100%;
      }

      @media (min-width: $screen-lg) {
        grid-column: 1 / 2;
        grid-row: 2;

        .search-container__inner {
          width: 320px;
        }
      }
    }
  }

  &__content {
    margin: 24px 0;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-template-rows: auto;

    .loading_fallback_container {
      position: relative;
      background-color: transparent;
      height: 200px;
      grid-column: 1 / span 4;
    }
  }

  &__brand {
    width: 100%;
    padding: 16px;
    @include flex-column;
    border-radius: 8px;
    border: 1px solid $content-default;
    background: $fill-bg-card;
    align-items: center;
    justify-self: center;

    > img {
      width: 120px;
      height: 120px;
      border-radius: 8px;
      object-fit: cover;
      margin-bottom: 24px;
    }

    > h3 {
      @include text-body-semibold;
      margin-bottom: 8px;
      word-break: break-word;
    }

    > a {
      @include text-body-small-medium;
      color: $content-primary;
      word-break: break-word;
    }
  }
}

.brand-view {
  @include flex-column;
  height: 100%;
  &__form {
    flex: 1;
    padding: $gap-secondary $gap-quaternary;
  }

  .editable_image {
    width: 200px;
    margin-bottom: 24px;

    label {
      @include text-body-semibold;
      align-self: flex-start;
    }
    &__image {
      width: 180px;
      height: 180px;
    }
  }

  &__website {
    margin-top: 20px;
  }

  &__actions {
    @include flex-row;
    justify-content: flex-end;
    gap: 16px;
    margin-top: auto;
    border-top: 1px solid $stroke-neutral-subtle;
    padding: $padding-primary;

    > button {
      width: 150px;
      height: 40px;
    }
  }
}
