@import '../../../../global/styles/config';

.credit {
  &__heading {
    margin: 20px 0 20px 0;
    @include flex-row;
    width: 100%;
    justify-content: space-between;
    gap: 5px;

    > h1 {
      @include h3-semibold;
      color: $content-default;
    }

    @media (max-width: $screen-md) {
      align-items: center;
      flex-wrap: wrap;
      row-gap: 24px;
    }

    &__balance {
      @include flex-row;
      align-items: center;
      gap: 24px;

      @media (max-width: $screen-md) {
        flex-direction: column;
        align-items: center;
        gap: 10px;
        width: 100%;
      }

      &__amount {
        @include flex-row;
        align-items: center;
        gap: 12px;

        > p {
          &:first-of-type {
            @include h3-normal;
            color: $content-default;
          }
        }

        &__total-balance {
          @include h3-semibold;
          color: $content-primary;
        }
      }

      button.btn-neutral-solid {
        @include label-medium;
        padding: $padding-tertiary $padding-primary;
      }
    }

    > button {
      @include flex-row;
      align-items: center;

      @media (min-width: $screen-md) {
        display: none;
      }
    }
  }

  &__main {
    &_actions {
      border-bottom: 1px solid $stroke-neutral-subtle;
      &_tabs {
        display: flex;
        align-items: center;
        gap: $gap-quaternary;

        > button {
          width: max-content;
          @include text-body-medium;
          padding: $padding-quinary 0;

          &._selected {
            border-bottom: 2px solid $stroke-neutral-press;
          }
        }
      }
    }

    &_history {
      padding: 15px 0;

      > h2 {
        @include h4-normal;
        margin-bottom: 10px;
      }

      .loading_fallback_container {
        position: relative;
        background: transparent;
        height: 200px;
      }

      .campaigns-list .single-campaign .small-dashboard-bar {
        grid-template-columns: 2fr 3fr;
      }
    }
  }
}
