@import '../../../global/styles/config';

.campaign-banner {
  @include flex-column;
  gap: 15px;
  align-items: center;
  position: relative;

  padding: 15px;
  margin: 0 -15px;

  border-radius: $corner-radius-primary;
  border: 1px solid $stroke-bg-card;
  background: $fill-bg-card;

  @media (min-width: $screen-md) {
    margin: 0;
    margin-bottom: 24px;
    display: grid;
    grid-template-columns: 80px 1fr 30px;
    grid-template-rows: repeat(3, 60px);
    align-items: flex-start;
    column-gap: 15px;
    row-gap: 0;
  }

  &__dots {
    position: absolute;
    top: -60px;
    right: 10px;

    @media (min-width: $screen-md) {
      top: 0;
    }

    @media (min-width: $screen-xl) {
      top: 0;
    }

    .select {
      border: none;
      background: transparent;

      &:hover {
        background: transparent;
        border: none;
      }

      > button {
        padding: 0;
        margin-top: 15px;
        > svg {
          display: none;
        }
      }

      .select__options {
        width: 100px;
        top: 30px;
        right: 0;
        left: unset;

        > button {
          @include flex-row;
          align-items: center;
          gap: 5px;

          svg {
            path {
              stroke: $content-default;
            }
          }
        }
      }
    }
  }

  &__title {
    @media (min-width: $screen-md) {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
    }

    > div {
      @include flex-column;
      gap: 8px;

      h2 {
        @include text-caption-normal;
        @include text-clamp(2);
        color: $content-default;
        text-align: center;

        @media (min-width: $screen-md) {
          @include text-caption-normal;
          text-align: left;
        }
      }
    }

    h1 {
      @include h4-semibold;
      @include text-clamp(1);
      text-align: center;
      @media (min-width: $screen-md) {
        text-align: left;
        @include h4-semibold;
      }
    }
  }

  &__date {
    @include text-body-normal;
    @include flex-row;
    color: $content-default;
    gap: 20px;

    @media (min-width: $screen-md) {
      gap: 10px;
    }

    .IN_PROGRESS {
      border: 1px solid $fill-primary-default;
      background: $fill-primary-default;
      color: $fixed-inverse;
    }

    .SCHEDULED {
      border-color: $stroke-warning-default;
      color: $content-warning-intense;
      background: $fill-warning-subtle;
    }

    .DRAFT {
      border: 1px solid $stroke-info-default;
      background: $fill-info-subtle;
      color: $fill-info-intense;
    }

    .FINISHED,
    .STOPPED {
      border: 1px solid $stroke-positive-default;
      background: $fill-positive-subtle;
      color: $content-positive-intense;
    }

    > p {
      color: $content-default;

      text-align: center;

      &:first-of-type {
        border: 1px solid;
        border-radius: 4px;
        padding: 0 4px;
      }
      @media (min-width: $screen-md) {
        text-align: left;
      }
    }
  }

  &__stats {
    width: 100%;
    @include flex-row;
    @include center;
    gap: 20px;

    @media (min-width: $screen-md) {
      grid-column: 1 / 3;
      grid-row: 2 / 3;
      justify-content: flex-start;
      align-self: flex-end;
    }

    > span {
      @include text-caption-normal;
      color: $content-default;
      border: 1px solid $content-default;
      padding: 4px;
      border-radius: $corner-radius-round;
    }

    > p {
      @include dots;
      @include text-body-normal;
      color: $content-default;

      &:not(:last-of-type)::after {
        top: -2px;
      }

      span {
        font-weight: $font-weight-bold;
      }
    }
  }

  &__description {
    width: 100%;

    @media (min-width: $screen-md) {
      grid-column: 2 / 3;
      grid-row: 3 / 4;
      margin-top: 6px;
    }

    > p {
      @include text-caption-normal;
      color: $content-default;
      word-break: break-all;
    }
  }

  &__action {
    @include flex-row;
    @include center;
    @include text-body-semibold;
    width: 100%;
    height: 40px;
    border-width: 1px;
    border-style: solid;
    border-radius: $corner-radius-primary;

    @media (min-width: $screen-md) {
      grid-column: 3 / span 4;
      grid-row: 1;
      width: 160px;
      margin-right: 50px;
    }

    &--stop {
      border-color: $content-negative;
      color: $content-negative;

      &:not(:disabled):hover {
        background: $content-negative;
        color: $fixed-inverse;
      }
      &:disabled {
        border-color: $content-negative;
        color: $content-negative;
        cursor: default;
      }
    }

    &--schedule {
      border-color: $stroke-warning-default;
      color: $content-warning-intense;
      background: $fill-warning-subtle;

      &:not(:disabled):hover {
        background: $content-primary;
        color: $fill-warning-subtle;
      }
    }

    &--unschedule {
      border-color: $stroke-warning-default;
      color: $content-warning-intense;
      background: $fill-warning-subtle;

      &:not(:disabled):hover {
        background: $stroke-warning-default;
        color: $fixed-inverse;
      }

      &:disabled {
        border-color: $stroke-warning-default;
        color: $content-warning-intense;
        cursor: default;
      }
    }

    svg {
      path {
        stroke: $fixed-inverse;
      }
    }
  }
}

.reactivate-campaign-modal {
  width: 100%;

  &__heading {
    @include flex-row;
    justify-content: space-between;

    > h2 {
      @include h4-semibold;
    }

    > button {
      @include flex-row;
      align-items: center;
    }
  }

  &__date {
    margin: 40px 0;
    > p:first-of-type {
      @include text-body-semibold;
      margin-bottom: 8px;
    }
    &_error {
      @include error-msg();
      height: 16px;
    }
  }

  &__actions {
    @include flex-row;
    gap: 10px;

    @media (min-width: $screen-md) {
      justify-content: flex-end;
    }
    > button {
      flex: 1;
      height: 40px;

      @media (min-width: $screen-md) {
        height: 56px;
        flex: unset;
        width: 150px;
      }
    }
  }
}
