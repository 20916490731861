@import '../../global/styles/config';

.table-container {
  width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;

    th,
    td {
      padding: 10px;
      border: none;
      text-align: left;

      &:not(:first-child) {
        text-align: right;
      }
    }

    th {
      background-color: $content-default;
    }
  }
}

.statistics {
  margin-top: 20px;
  background-color: $fill-primary-subtle;
  padding: 10px;
  border-radius: var(--corner-radius-primary, 8px);
  padding: $padding-primary;

  > p {
    @include text-body-semibold;
  }

  > div {
    @include flex-row;
    justify-content: space-around;
  }

  .statistic {
    text-align: center;

    .stat-value {
      @include h3-semibold;
      color: $content-primary;
    }

    .stat-name {
      @include text-body-normal;
      color: $content-description;
    }
  }
}
