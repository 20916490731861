@import '../../global/styles/config';

.checkbox-container {
  @include flex-row;
  @include text-body-small-medium;
  align-items: center;
  gap: $gap-tertiary;
  color: $content-default;
  .checkbox {
    @include flex-column;
    @include center;
    width: 16px;
    height: 16px;
    border: 1px solid $stroke-neutral-default;
    border-radius: $corner-radius-secondary;

    &--checked {
      background: $fill-primary-default;
    }
  }
}
