.stripe-modal {
  overflow-y: scroll;
  width: 100%;
  .checkout {
    > button {
      width: 100%;
      height: 40px;
      margin-top: 40px;
    }
  }

  > button {
    float: right;
  }
}
