@import '../../global/styles/config';

.loading_fallback_container {
  @include flex-row;
  @include center;
  position: absolute;
  background: $fill-bg-screen;
  border-radius: $corner-radius-tertiary;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  > img {
    width: 150px;
    height: 150px;
  }
}
