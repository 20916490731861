@import '../../../../../global/styles/config';

.select-ad {
  @include flex-column;
  padding-bottom: 0;
  overflow: hidden;
  height: 100%;

  > * {
    padding: 0 $padding-secondary;
  }

  > h2 {
    @include h3-semibold;
    color: $content-default;
    margin-bottom: $gap-content;
    padding-top: $padding-secondary;
  }

  > h3 {
    @include text-body-normal;
    color: $content-default;
    font-size: 16px;
    font-weight: 400;
  }

  &__nav {
    margin: $gap-secondary 0px;
    display: grid;
    align-items: center;
    grid-template-columns: 300px auto 1fr;
    gap: $gap-primary;

    .search-container {
      width: 300px;
      &__inner_search {
        height: 48px;
        border-color: $stroke-neutral-subtle;

        svg {
          width: 24px;
          height: 24px;
          path {
            stroke: $content-default;
          }
        }

        input {
          padding-left: 10px;
          &::placeholder {
            @include text-body-normal;
            color: $content-subtle;
          }
        }
      }
    }

    &_filters {
      @include flex-row;
      @include center;
      background: $fill-neutral-subtle;
      border-radius: $corner-radius-round;
      padding: $padding-content;

      > button {
        @include text-body-small-medium;
        color: $content-default;
        height: 36px;
        padding: 0 $padding-primary;
      }

      .active {
        @include text-body-small-semibold;
        background: $fill-bg-card;
        border-radius: $corner-radius-round;
      }
    }

    > button {
      @include label-medium;
      width: 115px;
      height: 48px;
      justify-self: end;
      gap: $gap-tertiary;

      svg {
        width: 24px;
        height: 24px;
        path {
          stroke: $content-primary;
        }
      }
    }
  }

  &__ads {
    margin-top: $gap-secondary;
    padding-top: $padding-tertiary;
    height: 100%;
    overflow: hidden;
    &_heading {
      @include flex-row;
      @include center;
      justify-content: space-between;

      > div {
        @include flex-row;
        @include center;
        gap: $gap-tertiary;
        p {
          @include text-body-medium;
          color: $content-default;
        }

        &:first-of-type {
          p {
            @include text-body-semibold;
          }

          svg {
            path {
              stroke: $content-default;
            }
          }
        }

        &:last-of-type {
          .checkbox {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    &_list {
      overflow-y: auto;
      display: grid;
      grid-template-columns: repeat(auto-fill, 220px);
      grid-auto-rows: 270px;
      gap: $gap-primary;
      padding: $padding-primary 0 50px;
      height: 100%;
      position: relative;
      .lds-ring {
        position: absolute;
        top: 200px;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &_item {
        @include flex-column;
        width: 220px;
        align-items: center;
        gap: $gap-tertiary;

        > div {
          border-radius: $corner-radius-primary;
          background: $fill-bg-card;
          border: 1px solid $stroke-neutral-subtle;
          width: 200px;
          height: 200px;
          padding: 8px;
          margin: 0 auto;
          position: relative;

          > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .checkbox-container {
            position: absolute;
            top: 8px;
            left: 8px;
            .checkbox {
              width: 24px;
              height: 24px;
              background: $fill-bg-card;

              &--checked {
                background: $content-primary;
              }
            }
          }
        }

        > p {
          @include text-body-medium;
          color: $content-default;

          &:last-of-type {
            @include text-body-small-normal;
            color: $content-description;
            margin-top: -4px;
          }
        }
      }
    }
  }

  &__ads_list_item {
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  &__selected_ads {
    @include flex-row;
    @include center;
    height: 100%;
    width: max-content;
    gap: $gap-primary;

    > button {
      border: none;

      svg {
        path {
          stroke: $content-default;
        }
      }
    }
    > p {
      @include text-body-medium;
      color: $content-default;
    }
  }
}
