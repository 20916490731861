@import '../../global/styles/config/';

.single-campaign .small-dashboard-bar {
  overflow: visible;
  border-radius: $corner-radius-primary;
  border: 1px solid $stroke-bg-card;
  background: $fill-bg-card;

  &:hover {
    border: 1px solid $stroke-primary-selected;
    background: $fill-primary-subtle;
  }

  @media (min-width: $screen-lg) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    height: 56px;
  }

  @media (min-width: $screen-xl) {
    grid-template-columns: 1fr 1fr;
  }

  img {
    display: none;
  }

  &:has(.FINISHED),
  &:has(.STOPPED) {
    .small-dashboard-bar__campaign_time {
      span {
        border: 1px solid $stroke-positive-default;
        background: $fill-positive-subtle;
        color: $content-positive-intense;
      }
    }
  }

  &:has(.SCHEDULED) {
    .small-dashboard-bar__campaign_time {
      span {
        border-color: $stroke-warning-default;
        color: $content-warning-intense;
        background: $fill-warning-subtle;
      }
    }
  }

  &:has(.DRAFT) {
    .small-dashboard-bar__campaign_time {
      span {
        border: 1px solid $stroke-info-default;
        background: $fill-info-subtle;
        color: $fill-info-intense;
      }
    }
  }

  &:has(.IN_PROGRESS) {
    .small-dashboard-bar__campaign_time {
      span {
        border: 1px solid $fill-primary-default;
        background: $fill-primary-default;
        color: $fixed-inverse;
      }
    }
  }

  &__info {
    flex: 1;
    > div {
      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }

      &:last-of-type {
        h2 {
          @include text-body-medium;
          position: relative;
          top: 0;
          color: $content-default;
        }

        h3 {
          display: none;
        }
      }
    }
  }

  &__campaign {
    @include flex-column;
    gap: 12px;

    @media (min-width: $screen-md) {
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      flex: 2;
      gap: 24px;
    }
    &__clean {
      position: absolute;
      right: -10px;
      top: -10px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }

    &_time {
      @include text-body-normal;
      @include flex-row;
      color: $content-description;
      align-items: center;
      height: 100%;
      justify-content: space-between;

      @media (min-width: $screen-lg) {
        @include text-body-small-normal;
        gap: 24px;
      }

      span {
        @include text-body-small-medium;
        width: 100px;
        text-align: center;
        border: 1px solid $green-50;
        margin-right: 8px;
        border-radius: $corner-radius-secondary;
      }
    }

    &_stats {
      @include flex-row;
      gap: 24px;
      align-items: center;

      @media (min-width: $screen-lg) {
        flex: 1;
      }

      > p {
        @include flex-row;
        @include center;
        @include text-body-medium;
        color: $content-default;
        gap: 4px;
        flex: 1;
      }

      &--no-ads {
        font-weight: $font-weight-normal !important;
        color: $content-default;
      }
    }
  }
}
