@import '../../../../../global/styles/config/';

.review-and-create-step {
  @include flex-column;
  gap: $gap-quaternary;

  &__review {
    @include flex-column;
    gap: $gap-secondary;

    img {
      max-width: 100%;
    }
  }
}
