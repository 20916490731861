@import '../../../global/styles/config';

.generate-api-credentials-modal {
  width: 100%;

  &__header {
    @include flex-row;
    justify-content: space-between;
    margin-bottom: 20px;
    > h2 {
      @include text-body-semibold;
    }
  }

  &__main {
    @include flex-column;
    gap: 20px;
    &_toggle {
      @include flex-row;
      gap: 20px;

      > label {
        @include text-body-semibold;
        color: $content-default;
      }
    }
  }

  &__actions {
    @include flex-row;
    @include center;
    gap: 10px;
    padding-top: 30px;
    > button {
      width: 150px;
      height: 40px;
    }

    @media (min-width: $screen-lg) {
      justify-content: flex-end;
    }
  }
}
