@import '../../../../global/styles/config';

.organization-game-ad-placement {
  display: flex;
  flex-direction: row;

  &__content {
    padding: $padding-content;
    width: 100%;
  }

  .aside-menu__mobile {
    display: none;
  }
}
