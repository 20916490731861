@import '../../../../global/styles/config';

.top-up-status-modal {
  width: 100%;
  padding: $padding-primary;
  z-index: 3;
  position: relative;

  &[class*='--error'] {
    .top-up-status-modal__heading {
      > div {
        background-color: $fill-negative-subtle-hover;

        svg {
          border-radius: $corner-radius-primary;
          background-color: $fill-negative-intense;
          path {
            stroke: $fill-negative-subtle-hover;
          }
        }
      }
    }
  }

  &__heading {
    @include flex-row;
    justify-content: space-between;
    margin-bottom: 15px;
    > div {
      @include flex-row;
      @include center;
      width: 64px;
      height: 64px;

      background-color: $fill-positive-intense;
      border-radius: $corner-radius-round;
      padding: 10px;

      svg {
        path {
          stroke: $content-default;
        }
      }
    }
    > button {
      display: flex;
    }
  }

  &__main {
    margin-bottom: $gap-secondary;

    > h2 {
      @include h4-semibold;
      color: $content-default;
    }

    > div {
      p {
        @include text-body-medium;
        color: $content-description;

        span {
          color: $content-description;
          font-weight: $font-weight-bold;
        }
      }
    }

    &_info {
      @include text-body-medium;
      margin-top: $padding-primary;

      b {
        color: $content-primary;
      }
    }

    &_hash {
      @include text-body-medium;
    }
  }

  > button {
    padding: 0 $padding-quaternary;

    height: 40px;
    float: right;
  }
}
