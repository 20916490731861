@import '../../../../global/styles/config';

.organization-game-players {
  background-color: $neutral-white;
  border-bottom-left-radius: $corner-radius-tertiary;
  border-bottom-right-radius: $corner-radius-tertiary;

  @media (min-width: $screen-xl) {
    margin-top: 24px;
  }

  .loading_fallback_container {
    position: relative;
    height: unset;
    background: unset;
  }

  .search-container {
    padding: 15px 0;
  }

  &__heading {
    margin-bottom: 15px;
    > h2 {
      @include text-body-semibold;
    }
  }

  &__main {
    @include flex-column;
    gap: 10px;
  }

  @media screen and (min-width: $screen-md) {
    padding-bottom: 0;
    &__main {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media only screen and (min-width: $screen-xl) {
    padding: 0 25px 25px;
    &__main {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
