@import '../../../../global/styles/config';

.reach-drawer-content {
  color: white;
  @include flex-column;
  padding: $padding-primary $padding-secondary;

  @media (min-width: $screen-lg) {
    width: 850px;
  }

  &__input {
    width: 100%;
  }

  &__dates {
    @include flex-row;
    gap: 24px;
    width: 100%;

    .date-picker {
      width: 100%;
    }
  }

  &__disclaimer {
    @include text-caption-normal;
    color: $content-description;
    @include flex-row;
    align-items: center;
    gap: 4px;
    margin: $gap-secondary 0;
  }

  .table-container {
    background-color: $fill-bg-card;
    th,
    td {
      padding: $padding-tertiary;
      text-align: left;
      border-bottom: 1px solid $stroke-neutral-subtle;
      background-color: $fill-bg-card;
    }

    th {
      @include text-body-small-bold;
      color: $content-description;
    }

    td {
      @include text-body-small-normal;
      color: $content-default;
    }
  }
}
