@import '../../global/styles/config';
.get-started {
  position: relative;
  border-radius: $corner-radius-primary;
  border: 1px solid $stroke-neutral-subtle;
  background: $fill-bg-card;
  padding: $padding-primary;

  @media (min-width: $screen-md) {
    position: static;
  }

  &_heading {
    margin-bottom: 12px;
    h3 {
      @include h3-semibold;
      color: $fill-neutral-default;
    }
  }

  &_dropdown {
    @include flex-column;
    gap: 16px;
    cursor: default;

    .step {
      display: grid;
      grid-template-columns: 1fr 20px;
      align-items: center;
      column-gap: 5px;

      a {
        @include text-body-normal;
        color: $content-default;

        svg {
          margin-left: 5px;
        }
      }

      > svg {
        path {
          stroke: $fill-secondary-default;
        }
      }

      &__done {
        > a {
          color: $content-disable;
        }
      }
    }
  }
}
