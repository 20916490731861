@import '../../../../global/styles/config';

.ad-surface-item {
  @include flex-column;
  gap: 16px;
  padding: 20px;
  border-radius: $corner-radius-primary;
  color: $neutral-white;

  &__body {
    @include flex-column;

    @media only screen and (min-width: $screen-md) {
      > div {
        width: 80%;
      }
    }

    &_maxMin {
      @include flex-row;
      justify-content: space-between;
      gap: 20px;

      .input-container {
        flex: 1;
      }
    }

    &_placeholder {
      word-break: break-all;
      margin-bottom: $gap-primary;
      label {
        @include text-body-semibold;
      }

      p {
        margin: 8px 0;
      }

      #video-container {
        @include flex-column;
        gap: $gap-primary;

        video {
          max-height: 200px;
          width: min-content;
        }
        button {
          width: fit-content;
        }
      }
    }

    &_stats {
      margin-top: 16px;
      font-weight: $font-weight-bold;
      color: $content-default;

      .statistics-container {
        width: 100px;
        margin-top: 8px;
      }
    }
  }
}

.loading {
  position: relative;
  opacity: 1;
  animation: breathing ease-in-out 1.5s infinite;
}

@keyframes breathing {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
