@import '../../../../global/styles/config';

.organization_settings {
  padding: 20px 0;
  @include flex-column;

  @media (min-width: $screen-lg) {
    width: 50%;
  }
  &__title {
    @include flex-row;
    align-items: center;
    gap: 5px;
    margin-bottom: 20px;

    > button {
      @include flex-row;
      align-items: center;

      @media (min-width: $screen-md) {
        display: none;
      }
    }

    > h3 {
      @include h3-semibold;
      color: $content-default;
      width: 140px;

      @media (min-width: $screen-md) {
        @include h4-semibold;
      }
    }
  }

  .editable_image {
    width: 200px;
    margin-bottom: 24px;

    label {
      @include text-body-semibold;
      align-self: flex-start;
    }
    &__image {
      width: 180px;
      height: 180px;
    }
  }

  .select {
    background-color: transparent;

    &__options {
      > button {
        height: unset;
      }
    }
    &__org-type {
      padding: 8px;

      > p {
        &:last-of-type {
          @include text-body-small-semibold;
          color: $content-default;
        }
      }
    }
  }

  > button {
    width: 160px;
    height: 40px;
  }

  .textarea-container {
    margin-bottom: 15px;
  }
}

.confirm-org-type-change {
  &__heading {
    @include flex-column;
    align-items: flex-end;
  }

  > h2 {
    @include text-body-semibold;
    text-align: center;
    width: 80%;
    margin: 40px auto;
  }

  &__action {
    @include flex-row;
    justify-content: center;
    gap: 20px;

    > button {
      height: 56px;
      width: 150px;
      @include text-body-semibold;
    }
  }
}
